import { QuestionMarkIcon } from '@radix-ui/react-icons';
import { Text } from '@radix-ui/themes';
import { CustomIconPath } from 'enums/custom.enums';
import { RadixColor } from 'lib_ts/enums/radix-ui';

const getContent = (icon: CustomIconPath) => {
  switch (icon) {
    case CustomIconPath.Void: {
      return (
        <svg
          width="0"
          height="16"
          viewBox="0 0 0 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        ></svg>
      );
    }

    case CustomIconPath.LowerMachine: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.49768H12.5C13.0523 2.49768 13.5 2.9454 13.5 3.49768V6.5C13.5 6.77614 13.7239 7 14 7C14.2761 7 14.5 6.77614 14.5 6.5V3.49768C14.5 2.39311 13.6046 1.49768 12.5 1.49768H11.7532C11.3495 1.49768 11.0008 1.81966 11 2.24319L5 2.24514C4.99905 1.82178 4.65039 1.49996 4.24684 1.49996L3.49998 1.49997C2.39542 1.49998 1.5 2.39541 1.5 3.49997V12.5L0.999981 12.5C0.723839 12.5 0.499989 12.7239 0.5 13C0.500011 13.2762 0.723877 13.5 1.00002 13.5L6 13.5C6.27614 13.5 6.5 13.2761 6.5 13C6.5 12.7238 6.27614 12.5 6 12.5H5V3.24514L11 3.24319V3.5C11 3.77614 11.2239 4 11.5 4C11.7761 4 12 3.77614 12 3.5V2.49768ZM4 12.5V2.49996L3.49999 2.49997C2.94771 2.49997 2.5 2.94769 2.5 3.49997V12.5H4ZM11.5 6.5C11.7761 6.5 12 6.72386 12 7V12.8866L14.6678 10.5152C14.8742 10.3317 15.1902 10.3503 15.3737 10.5567C15.5572 10.7631 15.5386 11.0791 15.3322 11.2626L11.8322 14.3737C11.6427 14.5421 11.3573 14.5421 11.1678 14.3737L7.66782 11.2626C7.46143 11.0791 7.44284 10.7631 7.6263 10.5567C7.80975 10.3503 8.12579 10.3317 8.33218 10.5152L11 12.8866V7C11 6.72386 11.2239 6.5 11.5 6.5Z"
            fill="currentColor"
          />
        </svg>
      );
    }

    case CustomIconPath.CheckCircledFilled: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.9999 0.935486C4.09837 0.935486 0.935547 4.09831 0.935547 7.99984C0.935547 11.9013 4.09837 15.0642 7.9999 15.0642C11.9014 15.0642 15.0642 11.9013 15.0642 7.99984C15.0642 4.09831 11.9014 0.935486 7.9999 0.935486ZM10.8362 5.90689C11.0057 5.66601 10.9478 5.33332 10.707 5.1638C10.4661 4.99429 10.1334 5.05215 9.96386 5.29304L6.95442 9.56961L5.71393 8.30617C5.50756 8.09599 5.16989 8.09289 4.95971 8.29926C4.74953 8.50561 4.74643 8.84329 4.9528 9.05346L6.64169 10.7736C6.75258 10.8866 6.90792 10.9444 7.06568 10.9315C7.22343 10.9187 7.36732 10.8363 7.45841 10.7069L10.8362 5.90689Z"
            fill="currentColor"
          />
        </svg>
      );
    }

    case CustomIconPath.ArrowAxis: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.63381 10.5C4.90582 11.745 2.51599 12.0013 1.93787 11C1.24751 9.80426 3.402 7.26793 6.75004 5.33493C10.0981 3.40193 13.3719 2.80426 14.0622 3.99999C14.6459 5.01098 13.1959 6.98031 10.7104 8.73304M10.7104 8.73304L10.7104 6.60608M10.7104 8.73304L12.7899 9.10533"
            stroke="currentColor"
            strokeLinecap="round"
          />
        </svg>
      );
    }

    case CustomIconPath.RepeatOne: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.57699 1.97735C3.78528 1.76907 3.78528 1.43138 3.57699 1.22311C3.36871 1.01482 3.03103 1.01482 2.82275 1.22311L0.689414 3.35644C0.481133 3.56472 0.481133 3.9024 0.689414 4.11068L2.82275 6.24401C3.03103 6.4523 3.36871 6.4523 3.57699 6.24401C3.78528 6.03574 3.78528 5.69805 3.57699 5.48977L2.35412 4.26689H10.1332C12.1951 4.26689 13.8665 5.93836 13.8665 8.00023C13.8665 8.29478 14.1054 8.53356 14.3999 8.53356C14.6944 8.53356 14.9332 8.29478 14.9332 8.00023C14.9332 5.34926 12.7842 3.20023 10.1332 3.20023H2.35412L3.57699 1.97735ZM2.1332 8.00023C2.1332 7.70568 1.89442 7.46689 1.59987 7.46689C1.30532 7.46689 1.06654 7.70568 1.06654 8.00023C1.06654 10.6512 3.21557 12.8002 5.86654 12.8002H13.6456L12.4227 14.0231C12.2145 14.2314 12.2145 14.5691 12.4227 14.7774C12.631 14.9856 12.9687 14.9856 13.177 14.7774L15.3104 12.6441C15.5186 12.4357 15.5186 12.098 15.3104 11.8897L13.177 9.75644C12.9687 9.54815 12.631 9.54815 12.4227 9.75644C12.2145 9.96472 12.2145 10.3024 12.4227 10.5107L13.6456 11.7336H5.86654C3.80467 11.7336 2.1332 10.0621 2.1332 8.00023Z"
            fill="currentColor"
          />
          <path
            d="M8.00253 10.8186V6.0842H7.94003L6.52206 7.09591V6.23654L8.00643 5.18185H8.88143V10.8186H8.00253Z"
            fill="currentColor"
          />
        </svg>
      );
    }

    case CustomIconPath.SwitchOn: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2 4.26666C9.13813 4.26666 7.46667 5.93813 7.46667 8C7.46667 10.0619 9.13813 11.7333 11.2 11.7333C13.2619 11.7333 14.9333 10.0619 14.9333 8C14.9333 5.93813 13.2619 4.26666 11.2 4.26666ZM0 8C0 5.34903 2.14903 3.2 4.8 3.2L11.2 3.2C13.851 3.2 16 5.34903 16 8C16 10.651 13.851 12.8 11.2 12.8L4.8 12.8C2.14903 12.8 0 10.651 0 8Z"
            fill="currentColor"
          />
        </svg>
      );
    }

    case CustomIconPath.SwitchOff: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.8 11.7333C6.86187 11.7333 8.53333 10.0619 8.53333 7.99999C8.53333 5.93813 6.86187 4.26666 4.8 4.26666C2.73813 4.26666 1.06667 5.93813 1.06667 7.99999C1.06667 10.0619 2.73813 11.7333 4.8 11.7333ZM7.81725 4.26666C8.90471 5.14666 9.6 6.49212 9.6 7.99999C9.6 9.50787 8.90471 10.8533 7.81725 11.7333L11.2 11.7333C13.2619 11.7333 14.9333 10.0619 14.9333 8C14.9333 5.93813 13.2619 4.26666 11.2 4.26666L7.81725 4.26666ZM16 8C16 10.651 13.851 12.8 11.2 12.8L4.8 12.8C2.14901 12.8 1.87875e-07 10.651 4.19629e-07 7.99999C6.51384e-07 5.34903 2.14901 3.19999 4.8 3.19999L11.2 3.19999C13.851 3.19999 16 5.34903 16 8Z"
            fill="currentColor"
          />
        </svg>
      );
    }

    case CustomIconPath.Beta: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 3.16663C0 2.06206 0.895431 1.16663 2 1.16663H14C15.1046 1.16663 16 2.06206 16 3.16663V12.8333C16 13.9379 15.1046 14.8333 14 14.8333H2C0.895431 14.8333 0 13.9379 0 12.8333V3.16663Z"
            fill="currentColor"
            fillOpacity="0.2"
          />
          <path
            d="M6.02202 10.5V4.68182H8.1527C8.56558 4.68182 8.90743 4.75 9.17827 4.88636C9.4491 5.02083 9.65175 5.2036 9.78622 5.43466C9.92069 5.66383 9.98793 5.92235 9.98793 6.21023C9.98793 6.45265 9.94342 6.6572 9.8544 6.82386C9.76539 6.98864 9.64607 7.12121 9.49645 7.22159C9.34872 7.32008 9.18584 7.39205 9.00781 7.4375V7.49432C9.20099 7.50379 9.38944 7.56629 9.57315 7.68182C9.75876 7.79545 9.91217 7.95739 10.0334 8.16761C10.1546 8.37784 10.2152 8.63352 10.2152 8.93466C10.2152 9.23201 10.1451 9.49905 10.005 9.7358C9.86671 9.97064 9.6527 10.1572 9.36293 10.2955C9.07315 10.4318 8.70289 10.5 8.25213 10.5H6.02202ZM6.89986 9.74716H8.1669C8.58736 9.74716 8.88849 9.66572 9.07031 9.50284C9.25213 9.33996 9.34304 9.13636 9.34304 8.89205C9.34304 8.70833 9.29664 8.53977 9.20384 8.38636C9.11103 8.23295 8.97846 8.1108 8.80611 8.01989C8.63565 7.92898 8.433 7.88352 8.19815 7.88352H6.89986V9.74716ZM6.89986 7.19886H8.07599C8.27296 7.19886 8.45005 7.16098 8.60724 7.08523C8.76634 7.00947 8.89228 6.90341 8.98509 6.76705C9.07978 6.62879 9.12713 6.46591 9.12713 6.27841C9.12713 6.03788 9.04285 5.83617 8.87429 5.6733C8.70573 5.51042 8.44721 5.42898 8.09872 5.42898H6.89986V7.19886Z"
            fill="currentColor"
          />
        </svg>
      );
    }

    case CustomIconPath.BetaBeaker: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
            fill="currentColor"
            fillOpacity="0.2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.81428 3.99998C5.81428 3.77906 5.99337 3.59998 6.21428 3.59998H9.78571C10.0066 3.59998 10.1857 3.77906 10.1857 3.99998C10.1857 4.22089 10.0066 4.39998 9.78571 4.39998H9.65V6.51135C9.65 6.86833 9.76938 7.21506 9.98916 7.49638L12.053 10.1381C12.7711 11.0572 12.1162 12.4 10.9497 12.4H5.05024C3.88383 12.4 3.22892 11.0572 3.94701 10.1381L6.01084 7.49638C6.23061 7.21506 6.35 6.86833 6.35 6.51134V4.39998H6.21428C5.99337 4.39998 5.81428 4.22089 5.81428 3.99998ZM7.15 4.39998V6.51134C7.15 7.04682 6.97092 7.56692 6.64125 7.98889L5.77322 9.09998H6.02362C6.59127 9.09998 7.15284 9.21696 7.67327 9.44363C8.3672 9.74585 9.14335 9.80277 9.87394 9.605L10.4909 9.438L9.35874 7.98889C9.02907 7.56692 8.85 7.04682 8.85 6.51135V4.39998H7.15ZM11.0253 10.1221L10.083 10.3772C9.17694 10.6225 8.21439 10.5519 7.35383 10.1771C6.93417 9.99431 6.48135 9.89998 6.02362 9.89998H5.14822L4.57743 10.6306C4.26967 11.0245 4.55035 11.6 5.05024 11.6H10.9497C11.4496 11.6 11.7303 11.0245 11.4226 10.6306L11.0253 10.1221Z"
            fill="currentColor"
          />
        </svg>
      );
    }

    case CustomIconPath.SuperAdmin: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 3.16663C0 2.06206 0.895431 1.16663 2 1.16663H14C15.1046 1.16663 16 2.06206 16 3.16663V12.8333C16 13.9379 15.1046 14.8333 14 14.8333H2C0.895431 14.8333 0 13.9379 0 12.8333V3.16663Z"
            fill="currentColor"
            fillOpacity="0.2"
          />
          <path
            d="M6.4476 6.21023C6.4173 5.94129 6.2923 5.73295 6.0726 5.58523C5.85291 5.43561 5.57639 5.3608 5.24306 5.3608C5.00442 5.3608 4.79798 5.39867 4.62374 5.47443C4.4495 5.5483 4.31408 5.65057 4.21749 5.78125C4.12279 5.91004 4.07545 6.05682 4.07545 6.22159C4.07545 6.35985 4.10764 6.47917 4.17204 6.57955C4.23832 6.67992 4.3245 6.7642 4.43056 6.83239C4.53851 6.89867 4.65404 6.95455 4.77715 7C4.90026 7.04356 5.01863 7.07955 5.13226 7.10795L5.70045 7.25568C5.88605 7.30114 6.07639 7.36269 6.27147 7.44034C6.46654 7.51799 6.64742 7.62027 6.81408 7.74716C6.98075 7.87405 7.11522 8.03125 7.21749 8.21875C7.32166 8.40625 7.37374 8.63068 7.37374 8.89205C7.37374 9.22159 7.28851 9.5142 7.11806 9.76989C6.9495 10.0256 6.70423 10.2273 6.38226 10.375C6.06219 10.5227 5.67488 10.5966 5.22033 10.5966C4.78473 10.5966 4.40783 10.5275 4.08965 10.3892C3.77147 10.2509 3.52241 10.0549 3.34249 9.80114C3.16257 9.54545 3.06313 9.24242 3.0442 8.89205H3.92488C3.94192 9.10227 4.0101 9.27746 4.12942 9.41761C4.25063 9.55587 4.40499 9.65909 4.59249 9.72727C4.78188 9.79356 4.98927 9.8267 5.21465 9.8267C5.46276 9.8267 5.6834 9.78788 5.87658 9.71023C6.07166 9.63068 6.22507 9.52083 6.33681 9.38068C6.44855 9.23864 6.50442 9.07292 6.50442 8.88352C6.50442 8.71117 6.45518 8.57008 6.3567 8.46023C6.2601 8.35038 6.12848 8.25947 5.96181 8.1875C5.79704 8.11553 5.61048 8.05208 5.40215 7.99716L4.71465 7.80966C4.24874 7.68277 3.87942 7.49621 3.6067 7.25C3.33586 7.00379 3.20045 6.67803 3.20045 6.27273C3.20045 5.9375 3.29135 5.64489 3.47317 5.39489C3.65499 5.14489 3.9012 4.95076 4.21181 4.8125C4.52241 4.67235 4.87279 4.60227 5.26295 4.60227C5.65688 4.60227 6.00442 4.6714 6.30556 4.80966C6.60859 4.94792 6.84723 5.13826 7.02147 5.38068C7.19571 5.62121 7.28662 5.89773 7.2942 6.21023H6.4476ZM8.89402 10.5H7.9622L10.0559 4.68182H11.0702L13.1639 10.5H12.2321L10.5872 5.73864H10.5417L8.89402 10.5ZM9.05027 8.22159H12.073V8.96023H9.05027V8.22159Z"
            fill="currentColor"
          />
        </svg>
      );
    }

    case CustomIconPath.TeamAdmin: {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 3.16663C0 2.06206 0.895431 1.16663 2 1.16663H14C15.1046 1.16663 16 2.06206 16 3.16663V12.8333C16 13.9379 15.1046 14.8333 14 14.8333H2C0.895431 14.8333 0 13.9379 0 12.8333V3.16663Z"
            fill="currentColor"
            fillOpacity="0.2"
          />
          <path
            d="M3.27644 5.4375V4.68182H7.77928V5.4375H5.96394V10.5H5.08894V5.4375H3.27644ZM8.60495 10.5H7.67313L9.76688 4.68182H10.7811L12.8748 10.5H11.943L10.2981 5.73864H10.2527L8.60495 10.5ZM8.7612 8.22159H11.7839V8.96023H8.7612V8.22159Z"
            fill="currentColor"
          />
        </svg>
      );
    }

    default: {
      return <QuestionMarkIcon />;
    }
  }
};

export const CustomIcon = (props: {
  icon: CustomIconPath;
  color?: RadixColor;
  title?: string;
}) => {
  return (
    <Text className="CustomIcon" title={props.title} color={props.color}>
      {getContent(props.icon)}
    </Text>
  );
};
