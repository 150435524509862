import { Vector3 } from 'three';
import {
  IPlateLoc,
  ITrajectory,
  ITrajectoryBreak,
} from '../interfaces/pitches';
import {
  IAziAltCoordinate,
  IPosition,
  IReleasePosition,
  IVelocity,
} from '../interfaces/pitches/i-base';
import { BallHelper } from './ball.helper';
import {
  FTPS_TO_MPH,
  FT_TO_INCHES,
  GRAVITY_FT_PER_SEC,
  getRotationMatrix,
} from './math.utilities';

/** represents the front of the strike zone */
const PLATE_Y_FT = (12 / Math.sqrt(2) + 8.5) / 12.0;

// 1/16th of inch => ft
const MAX_ROTATION_ERROR_FT = 1 / 16 / FT_TO_INCHES;

/** Approx 1 [degree per foot] (gt 1/2x, lt 2x, in [rad / foot]) */
const AZIMUTH_COEFF = 0.01;

/** Approx 1 [degree per foot] (gt 1/2x, lt 2x, in [rad / foot]) */
const ALTITUDE_COEFF = 0.01;

export class TrajHelper {
  static getSpeedMPH(value: Partial<ITrajectory>): number | undefined {
    if (value.vx === undefined) {
      return undefined;
    }

    if (value.vy === undefined) {
      return undefined;
    }

    if (value.vz === undefined) {
      return undefined;
    }

    return BallHelper.getSpeed(value as IVelocity) * FTPS_TO_MPH;
  }

  static getFlightTimeSec(value: ITrajectory): number {
    try {
      // don't bother if traj says pitch is moving in the wrong (or no) direction
      if (value.vy === 0) {
        throw new Error('cannot solve when vy is zero');
      }

      // solve for roots using binomial theorem
      const a = value.ay / 2;
      const b = value.vy;
      const c = value.py - PLATE_Y_FT;

      const discriminant = Math.pow(b, 2) - 4 * a * c;

      if (discriminant <= 0) {
        throw new Error(
          `cannot solve when discriminant is non-positive (${discriminant})`
        );
      }

      const output = (-b - Math.sqrt(discriminant)) / (2 * a);
      return output;
    } catch (e) {
      console.error(e);
      return NaN;
    }
  }

  static getPlateLoc(value: ITrajectory): IPlateLoc {
    try {
      const timeSec = this.getFlightTimeSec(value);

      if (isNaN(timeSec)) {
        throw new Error('timeSec is NaN');
      }

      return {
        plate_x:
          0.5 * value.ax * Math.pow(timeSec, 2) + value.vx * timeSec + value.px,
        plate_z:
          0.5 * value.az * Math.pow(timeSec, 2) + value.vz * timeSec + value.pz,
      };
    } catch (e) {
      console.error(e);

      return {
        plate_x: NaN,
        plate_z: NaN,
      };
    }
  }

  static getBreaks(value: ITrajectory): ITrajectoryBreak {
    try {
      const timeSec = this.getFlightTimeSec(value);

      if (isNaN(timeSec)) {
        throw new Error('timeSec is NaN');
      }

      return {
        zInches: 0.5 * (value.az + GRAVITY_FT_PER_SEC) * timeSec * timeSec * 12,
        xInches: 0.5 * value.ax * timeSec * timeSec * 12,
      };
    } catch (e) {
      console.error(e);

      return {
        zInches: NaN,
        xInches: NaN,
      };
    }
  }

  private static getTimeToPy(value: ITrajectory, new_py: number): number {
    // TODO: Assert that vy < -50, ay is positive, etc.
    if (Math.abs(value.ay) < 1e-5) {
      return (new_py - value.py) / value.vy;
    }
    return (
      (-value.vy -
        Math.sqrt(Math.pow(value.vy, 2) - 2 * value.ay * (value.py - new_py))) /
      value.ay
    );
  }

  static translate(input: ITrajectory, position: IPosition): ITrajectory {
    return {
      ...input,
      px: position.px,
      pz: position.pz,
      py: position.py,
    };
  }

  static extrapolateTrajectory(value: ITrajectory, py: number): ITrajectory {
    const delta = this.getTimeToPy(value, py);

    const output: ITrajectory = {
      ...value,
    };

    output.px = output.px + output.vx * delta + (output.ax * delta * delta) / 2;
    output.py = output.py + output.vy * delta + (output.ay * delta * delta) / 2;
    output.pz = output.pz + output.vz * delta + (output.az * delta * delta) / 2;
    output.vx = output.vx + output.ax * delta;
    output.vy = output.vy + output.ay * delta;
    output.vz = output.vz + output.az * delta;

    if (Math.abs(output.py - py) > 0.1) {
      console.warn({
        event: `extrapolateTrajectory to ${py} failed`,
        input: value,
        output: output,
      });
    }

    return output;
  }

  static getRotatedTrajectory(
    value: ITrajectory,
    rotation: IAziAltCoordinate
  ): ITrajectory {
    try {
      const mx = getRotationMatrix({
        x_rad: -rotation.altitude_rad,
        z_rad: rotation.azimuth_rad,
      });

      /** original velocity */
      const vVector = new Vector3(value.vx, value.vy, value.vz);

      /** rotated velocity */
      const vPrime = vVector.applyMatrix3(mx);

      /** original acceleration, gravity included */
      const aVector = new Vector3(
        value.ax,
        value.ay,
        value.az + GRAVITY_FT_PER_SEC
      );

      /** rotated acceleration, gravity included */
      const aPrime = aVector.applyMatrix3(mx);

      const output: ITrajectory = {
        px: value.px,
        py: value.py,
        pz: value.pz,
        vx: vPrime.x,
        vy: vPrime.y,
        vz: vPrime.z,
        ax: aPrime.x,
        ay: aPrime.y,
        az: aPrime.z - GRAVITY_FT_PER_SEC,
        wnet: value.wnet,
        model_id: value.model_id,
      };

      return output;
    } catch (e) {
      console.error(e);
      return { ...value };
    }
  }

  static getAltAzForTranslation(
    value: ITrajectory,
    release: IReleasePosition,
    plate: IPlateLoc
  ): IAziAltCoordinate {
    const safeTraj: ITrajectory = {
      ...value,
      px: release.px,
      pz: release.pz,
    };

    // will be modified in loop
    const output: IAziAltCoordinate = { altitude_rad: 0, azimuth_rad: 0 };

    // working, will be modified in loop
    const wPlate = this.getPlateLoc(safeTraj);

    while (
      Math.abs(wPlate.plate_x - plate.plate_x) > MAX_ROTATION_ERROR_FT ||
      Math.abs(wPlate.plate_z - plate.plate_z) > MAX_ROTATION_ERROR_FT
    ) {
      output.azimuth_rad += (plate.plate_x - wPlate.plate_x) * AZIMUTH_COEFF;
      output.altitude_rad += (plate.plate_z - wPlate.plate_z) * ALTITUDE_COEFF;

      const rTraj = this.getRotatedTrajectory(safeTraj, output);
      const rPlate = this.getPlateLoc(rTraj);

      wPlate.plate_x = rPlate.plate_x;
      wPlate.plate_z = rPlate.plate_z;
    }

    return output;
  }

  static calc_tr = (config: {
    ay: number;
    vy: number;
    py: number;
    new_py: number;
  }) => {
    // TODO: Assert that vy < -50, ay is positive, etc.
    if (Math.abs(config.ay) < 1e-5) {
      return (config.new_py - config.py) / config.vy;
    }
    return (
      (-config.vy -
        Math.sqrt(
          Math.pow(config.vy, 2) - 2 * config.ay * (config.py - config.new_py)
        )) /
      config.ay
    );
  };
}
