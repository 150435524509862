import { Box } from '@radix-ui/themes';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonTextInput } from 'components/common/form/text';
import { AimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { getAdjustmentsOptions, getSampleSizes } from 'enums/training.enums';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IPresetTrainingSpec } from 'lib_ts/interfaces/training/i-preset-training-spec';
import { useState } from 'react';

export const PresetForm = (props: {
  machineCx: IMachineContext;

  spec: IPresetTrainingSpec;
  setSpec?: (spec: Partial<IPresetTrainingSpec>) => void;

  disabled: boolean;
  showProbability: boolean;
}) => {
  const [_spec, _setSpec] = useState(props.spec);

  const [_sampleSizeOptions, _setSampleSizeOptions] = useState(
    getSampleSizes(props.machineCx.machine.training_threshold)
  );

  const [_adjustmentsOptions, _setAdjustmentsOptions] = useState(
    getAdjustmentsOptions()
  );

  const lazySetSpec = (value: Partial<IPresetTrainingSpec>) => {
    _setSpec({
      ..._spec,
      ...value,
    });

    props.setSpec?.(value);
  };

  return (
    <AimingContext.Consumer>
      {(aimingCx) => (
        <CommonFormGrid columns={2}>
          <CommonSelectInput
            id="precision-training-samples"
            name="sampleSize"
            label="tr.sample-shots"
            options={_sampleSizeOptions}
            value={_spec.sampleSize.toString()}
            onNumericChange={(v) => {
              if (v <= 0) {
                return;
              }

              lazySetSpec({
                sampleSize: v,
              });
            }}
            disabled={props.disabled}
            skipSort
          />

          <CommonSelectInput
            id="precision-training-iterations"
            name="iterations"
            label="tr.max-iterations"
            options={_adjustmentsOptions}
            value={_spec.iterations.toString()}
            onNumericChange={(v) => {
              if (v <= 0) {
                return;
              }

              lazySetSpec({
                iterations: v,
              });
            }}
            disabled={props.disabled}
            skipSort
          />

          <Box gridColumn="span 2">
            <CommonTextInput
              id="precision-training-max-speed"
              label="tr.max-speed-delta-mph"
              type="number"
              name="deltaSpeedMPH"
              value={_spec.deltaSpeedMPH.toString()}
              title="Provide a positive number"
              onNumericChange={(v) => {
                lazySetSpec({
                  deltaSpeedMPH: Math.abs(v),
                });
              }}
              disabled={props.disabled}
            />
          </Box>

          {aimingCx.pitch?.priority === BuildPriority.Breaks && (
            <Box gridColumn="span 2">
              <CommonTextInput
                id="precision-training-max-breaks"
                label="tr.max-breaks-delta-inches"
                type="number"
                name="deltaBreaksInches"
                value={_spec.deltaBreaksInches.toString()}
                title="Provide a positive number"
                onNumericChange={(v) => {
                  lazySetSpec({
                    deltaBreaksInches: Math.abs(v),
                  });
                }}
                disabled={props.disabled}
              />
            </Box>
          )}

          {aimingCx.pitch?.priority === BuildPriority.Spins && (
            <Box gridColumn="span 2">
              <CommonTextInput
                id="precision-training-max-spin"
                label="tr.max-spins-delta-rpm"
                type="number"
                name="deltaSpinsRPM"
                value={_spec.deltaSpinsRPM.toString()}
                title="Provide a positive number"
                onNumericChange={(v) => {
                  lazySetSpec({
                    deltaSpinsRPM: Math.abs(v),
                  });
                }}
                disabled={props.disabled}
              />
            </Box>
          )}

          <AuthContext.Consumer>
            {(authCx) => {
              if (
                authCx.current.role === UserRole.admin &&
                props.showProbability
              ) {
                return (
                  <Box gridColumn="span 2">
                    <CommonSwitchInput
                      id="precision-training-probability"
                      label="tr.use-probability"
                      labelIcon={<SuperAdminIcon />}
                      name="useProbability"
                      title="common.super-admins-only-msg"
                      defaultChecked={_spec.useProbability}
                      onCheckedChange={(v) =>
                        lazySetSpec({ useProbability: v })
                      }
                      disabled={props.disabled}
                    />
                  </Box>
                );
              }
            }}
          </AuthContext.Consumer>
        </CommonFormGrid>
      )}
    </AimingContext.Consumer>
  );
};
