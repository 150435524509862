import { IAcceleration, IPosition, IVelocity } from './i-base';

/**
 * note: velocity values (v[x/y/z]) should be in FTPS
 */
export interface ITrajectory extends IAcceleration, IPosition, IVelocity {
  /** net spin */
  wnet: number;

  model_id?: string;
}

export const DEFAULT_TRAJECTORY: ITrajectory = {
  px: 0,
  py: 55,
  pz: 5,
  vx: 0,
  vy: -110,
  vz: 1,
  ax: 0,
  ay: 20,
  az: -18,
  wnet: 0,
};

export const EMPTY_TRAJECTORY: ITrajectory = {
  px: 0,
  py: 0,
  pz: 0,
  vx: 0,
  vy: 0,
  vz: 0,
  ax: 0,
  ay: 0,
  az: 0,
  wnet: 0,
};

export interface ITrajectoryBreak {
  zInches: number;
  xInches: number;
}
