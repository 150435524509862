import { IButton } from 'interfaces/i-buttons';
import { RADIX } from 'lib_ts/enums/radix-ui';

export interface IBaseDialog {
  identifier: string;

  // shown in the footer
  buttons?: IButton[];

  onClose: () => void;

  /** whether the global context should count this for tracking what's open */
  ignore?: boolean;
}

interface INextPrev {
  handleNext?: () => void;
  handlePrev?: () => void;
}

export interface IFullDialog extends IBaseDialog, INextPrev {
  width: string;
  // if not provided, the container will hug content, else it will support vertical flexing
  vFlexHeight?: string;

  // if true, clicking on the overlay won't close the dialog
  modal?: boolean;

  // shows an indicator that something is loading
  loading?: boolean;

  // todo: refactor this so it's just a single prop that's a ReactNode
  title?: string;
  // todo: refactor this so it's just a single prop that's a ReactNode
  titleElement?: React.ReactNode;

  // e.g. text above a form describing what the form is for
  description?: string;

  content: React.ReactNode;
}

export const DEFAULT_ACCEPT_BTN: IButton = {
  label: 'common.accept',
  color: RADIX.COLOR.ACCENT,
};
