import {
  IPresetOption,
  IPresetTrainingSpec,
} from '../interfaces/training/i-preset-training-spec';

export enum TrackingDevice {
  RapsodoV3PRO = 'Rapsodo V3 PRO',
  TrackmanB1 = 'Trackman B1',
  StereoVision = 'Stereo Vision',
}

export enum PresetTrainingMode {
  Quick = 'Quick',
  Precision = 'Precision',
  Custom = 'Custom',
  Balanced = 'Balanced',
}

export const DEFAULT_PRESET_TRAINING_SPEC: IPresetTrainingSpec = {
  sampleSize: 3,
  iterations: 3,
  deltaSpeedMPH: 2,
  deltaSpinsRPM: 400,
  deltaBreaksInches: 4,
  useProbability: false,
};

const UNUSED_OPTIONS: IPresetOption[] = [
  {
    mode: PresetTrainingMode.Balanced,
    minPerPitch: 2,
    spec: {
      sampleSize: 6,
      iterations: 3,
      deltaSpeedMPH: 2,
      deltaBreaksInches: 4,
      deltaSpinsRPM: 400,
      useProbability: false,
    },
  },
];
