import { NotifyHelper } from 'classes/helpers/notify.helper';
import { EditSessionDialog } from 'components/common/sessions/dialogs/edit-session';
import { VisualizeSessionDialog } from 'components/common/sessions/dialogs/visualize-session';
import { ISettingsDialog } from 'components/common/settings-dialog';
import { IAuthContext } from 'contexts/auth.context';
import format from 'date-fns-tz/format';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { LOCAL_TIMEZONE } from 'enums/env';
import { SessionDialogMode } from 'enums/session.enums';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { CSVHelper } from 'lib_ts/classes/csv.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import {
  EMPTY_TRACKING_DATA,
  ICombinedData,
} from 'lib_ts/interfaces/csv/exports/i-session-fires';
import {
  IPitchStat,
  IPitchStatsFilters,
} from 'lib_ts/interfaces/data/i-pitch-stats';
import { ISessionSummary } from 'lib_ts/interfaces/i-session-summary';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { DataService } from 'services/data.service';
import {
  MAX_USER_SESSIONS,
  SessionEventsService,
} from 'services/session-events.service';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm:ss.SSS z';

interface IDialogConfig {
  session: string;
  mode: SessionDialogMode;
}

export interface ISessionEventsContext {
  sessions: ISessionSummary[];
  sessionsOptions: {
    [key: string]: string[];
  };

  selectedSession?: ISessionSummary;

  loading: boolean;

  fired: number;
  readonly increaseFired: () => void;

  /** open settings from anywhere, providing an undefined config will close it */
  readonly setSettingsDialog: (config?: ISettingsDialog) => void;

  /** undefined value =>  hidden */
  settingsDialog?: ISettingsDialog;

  /** pops the session details editor from anywhere */
  readonly showDialog: (config: IDialogConfig) => void;

  readonly getShotsData: (
    session: string,
    silently?: boolean
  ) => Promise<ICombinedData[]>;

  readonly getPitchStatsData: (
    filters: IPitchStatsFilters,
    silenty?: boolean
  ) => Promise<IPitchStat[]>;

  readonly refresh: () => void;

  readonly selectSession: (session: ISessionSummary) => void;
}

const DEFAULT: ISessionEventsContext = {
  sessions: [],
  sessionsOptions: {},

  loading: false,

  fired: 0,
  increaseFired: () => console.debug('not init'),

  setSettingsDialog: () => console.debug('not init'),

  showDialog: () => console.debug('not init'),
  getShotsData: () => new Promise(() => console.debug('not init')),
  getPitchStatsData: () => new Promise(() => console.debug('not init')),
  refresh: () => console.debug('not init'),
  selectSession: () => console.debug('not init'),
};

export const SessionEventsContext = createContext(DEFAULT);

const getSessionsOptions = (
  items: ISessionSummary[]
): {
  [key: string]: any[];
} => {
  if (items) {
    return {
      name: ArrayHelper.unique(items.map((m) => m.name)).sort(
        (a: string, b: string) => a.localeCompare(b)
      ),

      session: ArrayHelper.unique(items.map((m) => m.session)).sort(
        (a: string, b: string) => a.localeCompare(b)
      ),

      start: ArrayHelper.unique(
        items.map((m) => lightFormat(parseISO(m.start), 'yyyy-MM-dd'))
      ).sort((a: string, b: string) => a.localeCompare(b)),
    };
  } else {
    return {};
  }
};

interface IProps {
  authCx: IAuthContext;
  children: ReactNode;
}

export const SessionEventsProvider: FC<IProps> = (props) => {
  const [_sessions, _setSessions] = useState(DEFAULT.sessions);
  const [_sessionsOptions, _setSessionsOptions] = useState(
    getSessionsOptions(DEFAULT.sessions)
  );

  const [_selectedSession, _setSelectedSession] = useState(
    DEFAULT.selectedSession
  );

  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_lastFetched, _setLastFetched] = useState<Date | undefined>();

  const [_settingsDialog, _setSettingsDialog] = useState<
    ISettingsDialog | undefined
  >();
  const [_dialog, _setDialog] = useState<IDialogConfig | undefined>();

  /** used to count fire events sent for the current session */
  const [_fired, _setFired] = useState(DEFAULT.fired);

  const state: ISessionEventsContext = {
    sessions: _sessions,
    sessionsOptions: _sessionsOptions,

    selectedSession: _selectedSession,

    loading: _loading,

    settingsDialog: _settingsDialog,

    setSettingsDialog: (config) => {
      _setSettingsDialog(config);
    },

    showDialog: (config) => {
      if (config.mode === SessionDialogMode.none) {
        _setDialog(undefined);
        return;
      }

      _setDialog(config);
    },

    fired: _fired,
    increaseFired: () => {
      _setFired(_fired + 1);

      /** notify user after every 50th shot */
      if (_fired !== 0 && _fired % 50 === 0) {
        NotifyHelper.info({
          message_md: t('common.you-have-fired-x-shots', { x: _fired }),
        });
        NotifyHelper.haveFun();
      }
    },

    getShotsData: async (session, silently) => {
      try {
        _setLoading(true);

        if (!silently) {
          NotifyHelper.info({
            message_md: t('common.please-wait-request-processing'),
          });
        }

        const mstargets =
          await SessionEventsService.getExportShotsForSession(session);

        const output: ICombinedData[] = [];

        mstargets
          .filter((m) => m.fires && m.fires.length > 0)
          .forEach((target) => {
            try {
              const targetData = target.data;

              if (!targetData) {
                return;
              }

              const targetCreated = parseISO(target._created);

              const baseRow: ICombinedData = {
                Trajekt: {
                  MachineID: target.machine,
                  Session: session,
                  Date: format(targetCreated, DATE_FORMAT, {
                    timeZone: LOCAL_TIMEZONE,
                  }),
                  Timestamp: format(targetCreated, TIME_FORMAT, {
                    timeZone: LOCAL_TIMEZONE,
                  }),
                  ShotNumber: 0, // will be overwritten later

                  ...CSVHelper.mstargetToTrajekt(targetData),
                },

                /** ensures the column is printed/accounted for in CSV output, even if first row was only from mstarget */
                Tracking: { ...EMPTY_TRACKING_DATA },
              };

              /** fallback for no rapsodo data, check if machine was fired */
              const fires = target.fires ? target.fires : [];

              /** always create a record for each fire event, using shot details where possible */
              fires.forEach((f) => {
                const fireCreated = parseISO(f._created);

                const shot = f.shot ? (f.shot[0] as IMachineShot) : undefined;

                const targetBreaks =
                  targetData.breaks ??
                  (targetData.traj
                    ? TrajHelper.getBreaks(targetData.traj)
                    : undefined);

                const actualBreaks = (() => {
                  if (!shot?.traj) {
                    return undefined;
                  }

                  return TrajHelper.getBreaks(shot.traj);
                })();

                const row: ICombinedData = {
                  Trajekt: {
                    ...baseRow.Trajekt,

                    /** overwrite mstarget date + time with shot's date + time */
                    Date: format(fireCreated, DATE_FORMAT, {
                      timeZone: LOCAL_TIMEZONE,
                    }),
                    Timestamp: format(fireCreated, TIME_FORMAT, {
                      timeZone: LOCAL_TIMEZONE,
                    }),

                    InGame: f.data.in_game ? 'Y' : 'N',
                    Training: f.data.training ? 'Y' : 'N',
                    Valid: f.data.rejected ? 'N' : 'Y',
                    ErrorMsg: f.data.rejected_msg ?? '',

                    TargetVB: targetBreaks?.zInches,
                    TargetHB: targetBreaks ? -targetBreaks.xInches : undefined,

                    ActualVB: actualBreaks?.zInches,
                    ActualHB: actualBreaks ? -actualBreaks.xInches : undefined,

                    Hitter: f.data.hitterExt?.name,
                  },

                  Tracking: (() => {
                    if (f.rapsodo?.[0]) {
                      return CSVHelper.rapsodoToTracking(f.rapsodo[0]);
                    }

                    if (f.trackman) {
                      return CSVHelper.trackmanToTracking(f.trackman);
                    }

                    return baseRow.Tracking;
                  })(),
                };

                output.push(row);
              });
            } catch (e2) {
              console.error(e2);
            }
          });

        /** fix shot numbers */
        output.forEach((row, i) => (row.Trajekt.ShotNumber = i + 1));

        return output;
      } catch (e1) {
        console.error(e1);
        NotifyHelper.error({
          message_md:
            'Encountered an error while processing your request. Please try again later.',
        });

        return [];
      } finally {
        _setLoading(false);
      }
    },

    getPitchStatsData: async (filters, silently) => {
      try {
        _setLoading(true);

        if (!silently) {
          NotifyHelper.info({
            message_md: t('common.please-wait-request-processing'),
          });
        }

        return await DataService.getPitchStats(filters);
      } catch (e) {
        console.error(e);
        NotifyHelper.error({
          message_md:
            'Encountered an error while processing your request. Please try again later.',
        });
        return [];
      } finally {
        _setLoading(false);
      }
    },
    refresh: () => _setLastFetched(new Date()),
    selectSession: (session) => _setSelectedSession(session),
  };

  useEffect(() => {
    if (props.authCx.current.auth) {
      /** set fire count from db result when session changes */
      SessionEventsService.getFireCount(props.authCx.current.session).then(
        (count) => _setFired(count)
      );
    }
  }, [props.authCx.current.auth, props.authCx.current.session]);

  /** fetch the data at load */
  useEffect(() => {
    if (!_lastFetched) {
      return;
    }

    (async (): Promise<void> => {
      _setLoading(true);

      const sessions = await SessionEventsService.getUserSessions(
        props.authCx.current.userID,
        MAX_USER_SESSIONS
      );

      if (sessions) {
        _setSessions(sessions);
      } else {
        console.warn('Session events failed to load');
        _setSessions([]);
      }

      _setLoading(false);
    })();
  }, [_lastFetched]); // dependency list => run whenever lastFetched is changed

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh only once logged in/successfully resumed */
    if (props.authCx.current.auth && props.authCx.current.session) {
      _setLastFetched(new Date());
    }
  }, [props.authCx.current.auth, props.authCx.current.session]);

  useEffect(() => {
    _setSessionsOptions(getSessionsOptions(_sessions));
  }, [_sessions]); // dependency list => run whenever sessions is changed

  return (
    <SessionEventsContext.Provider value={state}>
      {props.children}

      {_dialog?.mode === SessionDialogMode.edit && (
        <EditSessionDialog
          identifier="EditSessionDialog"
          session={_dialog.session}
          fires={
            props.authCx.current.session === _dialog.session
              ? _fired
              : _sessions.find((s) => s.session === _dialog.session)?.fires ?? 0
          }
          onChanged={() => _setLastFetched(new Date())}
          onClose={() => _setDialog(undefined)}
        />
      )}

      {_dialog?.mode === SessionDialogMode.visualize && (
        <VisualizeSessionDialog
          identifier="VisualizeSessionDialog"
          session={_dialog.session}
          onClose={() => _setDialog(undefined)}
        />
      )}
    </SessionEventsContext.Provider>
  );
};
