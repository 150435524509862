import { CopyIcon, InfoCircledIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Box, Flex, Grid, Strong, Text } from '@radix-ui/themes';
import { BreakCanvas } from 'classes/break-canvas';
import { AimingContextHelper } from 'classes/helpers/aiming-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { BreakView } from 'components/common/break-view';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTooltip } from 'components/common/tooltip';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { t } from 'i18next';
import {
  FT_TO_INCHES,
  METERS_TO_FT,
  METERS_TO_INCHES,
} from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict, getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IBallState,
  IClosedLoopBuildChars,
  IPitch,
  ITrajectory,
} from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { IRapsodoBreak } from 'lib_ts/interfaces/training/i-rapsodo-shot';
import React from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { StateTransformService } from 'services/state-transform.service';

const COMPONENT_NAME = 'EditBreaksDialog';

// how many decimals to print
const PRECISION = 1;

// show warning if either x or z requested differs from corresponding avgs by more than this
const WARNING_THRESHOLD_INCHES = 10;

interface IProps {
  pitch: IPitch;
  listsCx: IPitchListsContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;

  // hides the update button
  readonly: boolean;
  onClose: () => void;
}

interface IState {
  actual_x_text?: string;
  actual_x_in: number;
  actual_z_text?: string;
  actual_z_in: number;

  target_x_text?: string;
  target_x_in: number;
  target_z_text?: string;
  target_z_in: number;

  overriding: boolean;
}

export class EditBreaksDialog extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    const safeBreaks = PitchListHelper.getSafePitchBreaks(props.pitch);

    this.state = {
      actual_x_in: 0,
      actual_z_in: 0,

      target_x_in: (safeBreaks?.xInches ?? 0) * -1,
      target_z_in: safeBreaks?.zInches ?? 0,

      overriding: false,
    };

    this.getMedianBreaks = this.getMedianBreaks.bind(this);
    this.getShotsWithBreaks = this.getShotsWithBreaks.bind(this);
    this.initializeValues = this.initializeValues.bind(this);
    this.resetActualBreaks = this.resetActualBreaks.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;
    this.initializeValues();
  }

  private async initializeValues() {
    if (this.getShotsWithBreaks().length === 0) {
      await this.props.matchingCx.updatePitch(
        {
          pitch: this.props.pitch,
          includeHitterPresent: false,
          includeLowConfidence: false,
        },
        true
      );
    }

    const target =
      this.props.pitch.priority === BuildPriority.Breaks
        ? this.props.pitch.breaks
        : undefined;

    // also used as a fallback for target
    const actual = this.getMedianBreaks();

    const actual_x_in = actual.PITCH_HBTrajectory * METERS_TO_INCHES;
    const actual_z_in = actual.PITCH_VBTrajectory * METERS_TO_INCHES;

    const target_x_in = target ? -1 * target.xInches : actual_x_in;
    const target_z_in = target ? target.zInches : actual_z_in;

    this.setState({
      actual_x_in: actual_x_in,
      actual_x_text: actual_x_in.toFixed(PRECISION),
      actual_z_in: actual_z_in,
      actual_z_text: actual_z_in.toFixed(PRECISION),

      target_x_in: target_x_in,
      target_x_text: target_x_in.toFixed(PRECISION),
      target_z_in: target_z_in,
      target_z_text: target_z_in.toFixed(PRECISION),
    });
  }

  private resetActualBreaks() {
    const medianBreaks = this.getMedianBreaks();

    this.setState({
      actual_x_in: medianBreaks.PITCH_HBTrajectory * METERS_TO_INCHES,
      actual_x_text: (
        medianBreaks.PITCH_HBTrajectory * METERS_TO_INCHES
      ).toFixed(PRECISION),

      actual_z_in: medianBreaks.PITCH_VBTrajectory * METERS_TO_INCHES,
      actual_z_text: (
        medianBreaks.PITCH_VBTrajectory * METERS_TO_INCHES
      ).toFixed(PRECISION),
    });
  }

  private getShotsWithBreaks(): IMachineShot[] {
    return this.props.matchingCx
      .safeGetShotsByPitch(this.props.pitch)
      .filter((s) => !!s.break);
  }

  private getShotsWithTraj(): IMachineShot[] {
    return this.props.matchingCx
      .safeGetShotsByPitch(this.props.pitch)
      .filter((s) => !!s.traj);
  }

  private getMedianBreaks() {
    const breaks = this.getShotsWithBreaks().map(
      (s) => s.break as IRapsodoBreak
    );

    return BreakCanvas.getMedian(breaks);
  }

  private getMedianTraj(): ITrajectory | undefined {
    const trajs = this.getShotsWithTraj().map((s) => s.traj as ITrajectory);

    if (trajs.length === 0) {
      return undefined;
    }

    return MiscHelper.getMedianObject(trajs) as ITrajectory;
  }

  private async submit(mode: 'copy' | 'update') {
    if (mode === 'update' && this.props.readonly) {
      NotifyHelper.error({
        message_md: 'Updating is not allowed in read-only mode.',
      });
      return;
    }

    const medianBS = MiscHelper.getMedianObject(
      this.getShotsWithBreaks().map((s) => s.bs as IBallState)
    ) as IBallState;

    const medianBreaks = this.getMedianBreaks();
    if (!medianBreaks) {
      NotifyHelper.error({
        message_md: 'There is insufficient data to proceed.',
      });
      return;
    }

    const medianTraj = this.getMedianTraj();
    if (!medianTraj) {
      NotifyHelper.error({
        message_md: 'There is insufficient data to proceed.',
      });
      return;
    }

    const msResult = getMSFromMSDict(
      this.props.pitch,
      this.props.machineCx.machine
    );
    if (!msResult.ms) {
      NotifyHelper.error({ message_md: 'There is no machine state.' });
      return;
    }

    const chars: IClosedLoopBuildChars = {
      machineID: this.props.machineCx.machine.machineID,
      mongo_id: this.props.pitch._id,
      priority: BuildPriority.Breaks,
      use_gradient: true,
      ms: msResult.ms,
      traj: medianTraj,
      target_bs: this.props.pitch.bs,
      actual_bs: medianBS ?? this.props.pitch.bs,
      actual_mvmt: {
        break_x_ft: -1 * medianBreaks.PITCH_HBTrajectory * METERS_TO_FT,
        break_z_ft: medianBreaks.PITCH_VBTrajectory * METERS_TO_FT,
      },
      target_mvmt: {
        break_x_ft: (-1 * this.state.target_x_in) / FT_TO_INCHES,
        break_z_ft: this.state.target_z_in / FT_TO_INCHES,
      },
      override_mvmt: this.state.overriding
        ? {
            break_x_ft: (-1 * this.state.actual_x_in) / FT_TO_INCHES,
            break_z_ft: this.state.actual_z_in / FT_TO_INCHES,
          }
        : undefined,
    };

    const result = (
      await StateTransformService.getInstance().buildClosedLoop({
        machineID: this.props.machineCx.machine.machineID,
        pitches: [chars],
        stepSize: 1,
        notifyError: true,
      })
    ).find((p) => p.mongo_id === this.props.pitch._id);

    if (!result) {
      NotifyHelper.error({
        message_md: 'Failed to find adjusted pitch by ID.',
      });
      return;
    }

    const nextMSDict = getMergedMSDict(
      this.props.machineCx.machine,
      [result.ms],
      this.props.pitch.msDict
    );

    const payload: Partial<IPitch> = {
      ...this.props.pitch,
      bs: result.target_bs,
      traj: result.traj,
      breaks: {
        xInches: this.state.target_x_in * -1,
        zInches: this.state.target_z_in,
      },
      msDict: nextMSDict,
      priority: BuildPriority.Breaks,
    };

    const aimed = AimingContextHelper.getAdHocAimed({
      source: `${COMPONENT_NAME} > submit`,
      machine: this.props.machineCx.machine,
      pitch: payload as IPitch,
      plate: TrajHelper.getPlateLoc(this.props.pitch.traj),
      usingShots: [],
    });

    if (!aimed) {
      NotifyHelper.error({ message_md: 'Failed to build new pitch.' });
      return;
    }

    switch (mode) {
      case 'copy': {
        aimed.pitch._original_id = this.props.pitch._id;
        // add "(VB:..., HB:...)" to end of name to differentiate
        const nameSuffix = `(HB: ${this.state.target_x_in.toFixed(
          PRECISION
        )}, VB: ${this.state.target_z_in.toFixed(PRECISION)})`;
        aimed.pitch.name = `${this.props.pitch.name} ${nameSuffix}`;

        await PitchListsService.getInstance()
          .postPitchesToList({
            listID: this.props.pitch._parent_id,
            data: [aimed.pitch],
          })
          .then((result) => {
            if (!result.success) {
              NotifyHelper.warning({
                message_md: result.error ?? 'Pitch could not be created.',
              });
              return;
            }

            /** update the active pitches to show newly added pitch */
            if (
              this.props.pitch._parent_id === this.props.listsCx.active?._id
            ) {
              this.props.listsCx.refreshActive();
            }
          });
        break;
      }

      case 'update': {
        await this.props.listsCx.updatePitches({ payloads: [aimed.pitch] });
        break;
      }

      default: {
        break;
      }
    }

    this.props.onClose();
  }

  render() {
    const pitchName = this.props.pitch.name;
    const shots = this.getShotsWithBreaks();

    const diffX = this.state.actual_x_in - this.state.target_x_in;
    const warnX = Math.abs(diffX) > WARNING_THRESHOLD_INCHES;

    const diffZ = this.state.actual_z_in - this.state.target_z_in;
    const warnZ = Math.abs(diffZ) > WARNING_THRESHOLD_INCHES;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          title={t('pl.edit-breaks') + ` for "${pitchName}"  (BETA)`}
          width={RADIX.DIALOG.WIDTH.LG}
          onClose={this.props.onClose}
          buttons={[
            {
              label: `Override: ${this.state.overriding ? 'ON' : 'OFF'}`,
              color: this.state.overriding ? RADIX.COLOR.WARNING : undefined,
              invisible: shots.length === 0,
              onClick: () =>
                this.setState(
                  {
                    overriding: !this.state.overriding,
                  },
                  () => this.resetActualBreaks()
                ),
            },
            {
              icon: <CopyIcon />,
              label: 'common.copy',
              color: RADIX.COLOR.SUCCESS,
              disabled: shots.length === 0,
              invisible: shots.length === 0,
              onClick: () => this.submit('copy'),
            },
            {
              icon: <UpdateIcon />,
              label: 'common.update',
              color: RADIX.COLOR.WARNING,
              invisible: shots.length === 0 || this.props.readonly,
              onClick: () => this.submit('update'),
            },
          ]}
          content={
            <Flex gap={RADIX.FLEX.GAP.LG} direction="column">
              <Text>
                Use this feature to directly control the breaks of a pitch. This
                feature will save a new pitch with the adjusted breaks.
              </Text>

              {shots.length === 0 && (
                <CommonCallout text="There is insufficient break data to use this feature at this time. Please retrain this pitch using Quick Train and try again." />
              )}

              <BreakView
                actual_x_in={this.state.actual_x_in}
                actual_z_in={this.state.actual_z_in}
                target_x_in={this.state.target_x_in}
                target_z_in={this.state.target_z_in}
                shots={shots}
                overriding={this.state.overriding}
                onUpdate={(result) => {
                  this.setState({
                    target_x_text: result.xInches.toFixed(PRECISION),
                    target_x_in: result.xInches,
                    target_z_text: result.zInches.toFixed(PRECISION),
                    target_z_in: result.zInches,
                  });
                }}
              />

              <Grid columns="3" gap={RADIX.FLEX.GAP.MD}>
                {/* row 1 */}
                <Box></Box>
                <Box>
                  {this.state.overriding ? 'User Override' : 'Actual'} &nbsp;
                  <CommonTooltip
                    trigger={<InfoCircledIcon />}
                    text_md={
                      this.state.overriding
                        ? 'Actual break as input by user'
                        : 'Actual break as reported by Rapsodo'
                    }
                  />
                </Box>
                <Box>
                  <CommonTooltip
                    trigger={<Strong>Horizontal Break (in)</Strong>}
                    text_md={PitchDesignHelper.HB_TOOLTIP_TEXT}
                  />
                </Box>
                {/* row 2 */}
                <Box>Horizontal Break (in)</Box>
                <Box>
                  <CommonTextInput
                    id="edit-breaks-actual-x"
                    name="actual_x_text"
                    type="number"
                    className="align-center"
                    value={this.state.actual_x_text}
                    disabled={
                      this.props.listsCx.loading || !this.state.overriding
                    }
                    onOptionalNumericChange={(v) => {
                      this.setState({
                        actual_x_text: v?.toString(),
                        actual_x_in: v ?? 0,
                      });
                    }}
                  />
                </Box>
                <Box>
                  <CommonTextInput
                    id="edit-breaks-target-x"
                    name="target_x_text"
                    type="number"
                    className="align-center"
                    value={this.state.target_x_text}
                    disabled={this.props.listsCx.loading}
                    onOptionalNumericChange={(v) => {
                      this.setState({
                        target_x_text: v?.toString(),
                        target_x_in: v ?? 0,
                      });
                    }}
                  />
                </Box>
                {/* row 3 */}
                <Box>Vertical Break (in)</Box>
                <Box>
                  <CommonTextInput
                    id="edit-breaks-actual-z"
                    name="actual_z_text"
                    type="number"
                    className="align-center"
                    value={this.state.actual_z_text}
                    disabled={
                      this.props.listsCx.loading || !this.state.overriding
                    }
                    onOptionalNumericChange={(v) => {
                      this.setState({
                        actual_z_text: v?.toString(),
                        actual_z_in: v ?? 0,
                      });
                    }}
                  />
                </Box>
                <Box>
                  <CommonTextInput
                    id="edit-breaks-target-z"
                    name="target_z_text"
                    type="number"
                    className="align-center"
                    value={this.state.target_z_text}
                    disabled={this.props.listsCx.loading}
                    onOptionalNumericChange={(e) => {
                      this.setState({
                        target_z_text: e?.toString(),
                        target_z_in: e ?? 0,
                      });
                    }}
                  />
                </Box>
              </Grid>

              {(warnX || warnZ) && (
                <CommonCallout
                  text={(() => {
                    if (warnX && warnZ) {
                      return `Both target values differ by more than ${WARNING_THRESHOLD_INCHES} inches from actual values.`;
                    }

                    if (warnX) {
                      return `Horizontal target value differs by more than ${WARNING_THRESHOLD_INCHES} inches from actual value.`;
                    }

                    if (warnZ) {
                      return `Vertical target value differs by more than ${WARNING_THRESHOLD_INCHES} inches from actual value.`;
                    }
                  })()}
                />
              )}
            </Flex>
          }
        />
      </ErrorBoundary>
    );
  }
}
