import {
  CircleIcon,
  EyeOpenIcon,
  GlobeIcon,
  LoopIcon,
  UpdateIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, IconButton } from '@radix-ui/themes';
import { SpeedControlView } from 'components/common/animation/speed-ctrl';
import { ErrorBoundary } from 'components/common/error-boundary';
import {
  addSceneTextures,
  getNextOrientation,
  loadColors,
  loadMaterialBall,
  loadStrikeZone,
  positionAtTime,
  SceneEngine,
  seekArrivalTime,
} from 'components/common/trajectory-view/helpers';
import {
  FPS,
  LINE_WIDTH,
  Perspective,
  PLATE_HEIGHT,
  POINT_OFFSETS,
  POV_OPTIONS,
  SPEEDS,
  STRIKEZONE,
} from 'components/common/trajectory-view/helpers/constants';
import {
  IInputModel,
  IModelGroup,
  ISceneModel,
} from 'components/common/trajectory-view/helpers/interfaces';
import { t } from 'i18next';
import { RAD_RIGHT_ANGLE } from 'lib_ts/classes/math.utilities';
import { RADIX, RadixBtnVariant, RadixColor } from 'lib_ts/enums/radix-ui';
import React from 'react';
import {
  ACESFilmicToneMapping,
  BufferGeometry,
  Euler,
  Line,
  LineBasicMaterial,
  Object3D,
  PerspectiveCamera,
  Quaternion,
  sRGBEncoding,
  Event as ThreeEvent,
  Vector3,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

const COMPONENT_NAME = 'TrajectoryView';

const BTN_COLOR: RadixColor = RADIX.COLOR.NEUTRAL;
const BTN_CLASS = 'btn-floating';
const BTN_VARIANT: RadixBtnVariant = 'soft';

interface IProps {
  models: IInputModel[];

  /** whether the user can click and drag or zoom the camera */
  disableCtrlCamera?: boolean;

  /** whether the user can change speed or play/pause the animation */
  disableCtrlPlayback?: boolean;

  /** whether the user can cycle through POVs */
  disableCtrlPOV?: boolean;

  /** whether the ball spins and whether the user can toggle it */
  disableCtrlSpinning?: boolean;

  /** default will be batter (i.e. behind home plate, facing towards mound) */
  startingPOV?: Perspective;

  startingZoom?: number;
}

interface IState {
  speed: number;
  inactive: string[];

  /** for user to toggle on/off spinning */
  spinning: boolean;
  camera_pov: Perspective;

  groups: IModelGroup[];
}

export class TrajectoryView extends React.Component<IProps, IState> {
  private node?: HTMLDivElement;
  private init = false;

  /** how frequently to redraw the scene */
  private animationInterval?: any;

  private width = 400;
  private height = 400;
  private lastTime = 0;
  private loopCount = 0;
  private camera = new PerspectiveCamera();
  private scene = new Object3D<ThreeEvent>();
  private engine = new SceneEngine();
  private renderer = new WebGLRenderer({ antialias: true });
  private controls = new OrbitControls(this.camera, this.renderer.domElement);

  constructor(props: IProps) {
    super(props);

    this.state = {
      speed: 5,
      inactive: [],

      camera_pov: props.startingPOV ?? 'batter',
      spinning: true,

      groups: [],
    };

    this.animate = this.animate.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.initializeScene = this.initializeScene.bind(this);
    this.postSceneLoaded = this.postSceneLoaded.bind(this);
    this.restartAnimation = this.restartAnimation.bind(this);
    this.stepBall = this.stepBall.bind(this);
    this.upsertBall = this.upsertBall.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);

    if (!this.init) {
      this.init = true;
      this.initializeScene();
      this.lastTime = performance.now();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);

    clearInterval(this.animationInterval);
  }

  /** loads a ball based on pitch and adds to engine + scene
   * replaces any existing models with the same guid */
  async upsertBall(pitch: IInputModel) {
    const model: ISceneModel = await (async () => {
      const existing = this.engine.models.find(
        (b) => b.source.guid === pitch.guid
      );

      if (existing) {
        /** sync local values */
        existing.traj_local = { ...pitch.traj };
        existing.bs_local = { ...pitch.bs };

        /** reset arrival to recalculate it (since speed might change) */
        existing.arrival_time = undefined;

        return existing;
      }

      // create a new ball
      const ball = await loadMaterialBall(this.scene);
      return this.engine.addBall(pitch, ball);
    })();

    /** starting position is the release position */
    model.ball.position.x = pitch.traj.px;
    model.ball.position.y = pitch.traj.py;
    model.ball.position.z = pitch.traj.pz;
  }

  /** should never be re-run, otherwise the view will break */
  private async initializeScene() {
    if (!this.node) {
      console.warn(
        `${COMPONENT_NAME}: node was not defined, abandoning repeated initialization`
      );
      return;
    }

    const scene = await loadColors();
    if (!scene) {
      console.warn(
        `${COMPONENT_NAME}: scene failed to load, abandoning repeated initialization`
      );
      return;
    }

    /** set scene for use by component */
    this.scene = scene;

    /** load additional items from elements module */
    addSceneTextures(this.scene);

    await loadStrikeZone(this.scene);

    await Promise.all(this.props.models.map((p) => this.upsertBall(p)));

    this.postSceneLoaded();
    this.resetCamera();

    this.restartAnimation('initializeScene');
  }

  private animate() {
    const now = performance.now();

    /** ms since last execution */
    const deltaMS = now - this.lastTime;
    this.lastTime = now;

    /** convert deltaMS from ms to seconds, adjust by speed as a fraction of max value */
    const delta = (deltaMS / 1_000) * (this.state.speed / SPEEDS.MAX);

    this.engine.models.forEach((m) => {
      const inactive = this.state.inactive.includes(m.source.button_group.name);

      m.ball.visible = !inactive;

      if (inactive) {
        /** remove lines */
        m.lines.forEach((l) => this.scene.remove(l));
        return;
      }

      /** iterate ball spin, position, and tracers */
      this.stepBall(delta, m);
      this.drawLineFromPoints(m);
    });

    this.renderer.render(this.scene, this.camera);
  }

  private drawLineFromPoints = (model: ISceneModel) => {
    /** remove the old lines */
    model.lines.forEach((line) => this.scene.remove(line));

    /** compute new lines  */
    const newLines = POINT_OFFSETS.map((offset) => {
      const offsetPoints = model.points[0].map((p) => p.clone().add(offset));
      const geometry = new BufferGeometry().setFromPoints(offsetPoints);
      const material = new LineBasicMaterial({
        linewidth: LINE_WIDTH,
        color: model.source.color,
      });
      return new Line(geometry, material);
    });

    /** update reference */
    model.lines = newLines;

    /** add lines to scene */
    newLines.forEach((line) => this.scene.add(line));
  };

  /** put camera at default position */
  private resetCamera() {
    this.camera.position.y = PLATE_HEIGHT;

    switch (this.state.camera_pov) {
      /** batter pov */
      case 'batter': {
        this.controls.target.set(0, PLATE_HEIGHT, 0);
        this.camera.position.z = 8;
        this.camera.position.x = 0;
        break;
      }

      /** pitcher pov */
      default: {
        this.controls.target.set(0, PLATE_HEIGHT, -50);
        this.camera.position.z = -70;

        switch (this.state.camera_pov) {
          case 'pitcher-1': {
            this.camera.position.x = 20;
            break;
          }

          case 'pitcher-2': {
            this.camera.position.x = 0;
            break;
          }

          case 'pitcher-3': {
            this.camera.position.x = -20;
            break;
          }

          default: {
            break;
          }
        }
        break;
      }
    }

    this.controls.update();
  }

  /** setup for portal dimensions, renderer, controls, and lighting  */
  private postSceneLoaded = () => {
    if (!this.node) {
      console.warn(
        `${COMPONENT_NAME}: visualizerNode was not ready for postSceneSetup`
      );
      return;
    }

    this.width = this.node.clientWidth;
    this.height = this.node.clientHeight;

    this.camera = new PerspectiveCamera(
      45,
      this.width / this.height,
      0.1,
      1000
    );

    if (this.props.startingZoom) {
      this.camera.zoom = this.props.startingZoom;
    }

    this.renderer = new WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.toneMapping = ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1;
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.setClearColor(0x000000, 0);

    this.node.appendChild(this.renderer.domElement);

    if (!this.props.disableCtrlCamera) {
      /** setup controls to pan (drag) and zoom (scroll)  */
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.maxPolarAngle = RAD_RIGHT_ANGLE;
      this.controls.minPolarAngle = -RAD_RIGHT_ANGLE;
      this.controls.update();
    }

    /** add lighting - doesn't do anything? */
    // this.scene.add(new AmbientLight(0xffffff, 1.0));

    this.handleWindowResize();
  };

  restartAnimation(source: string) {
    this.loopCount++;

    this.props.models.forEach((ball) => this.upsertBall(ball));
    this.setState({ groups: this.engine.sortedGroups() }, () => {
      // restart the animation for each model
      this.engine.models.forEach((m) => {
        /** reset points */
        m.points = POINT_OFFSETS.map(() => []);

        /** remove old lines */
        m.lines.forEach((l) => this.scene.remove(l));

        /** calculate starting position if model is not excluded */
        const source = this.props.models.find((p) => p.guid === m.source.guid);

        if (!source) {
          console.warn(
            `${COMPONENT_NAME}: unable to locate pitch for model with GUID "${m.source.guid}"`
          );
          return;
        }

        /** quaternion representing starting orientation */
        const oQuat = new Quaternion(
          source.bs.qx,
          source.bs.qy,
          source.bs.qz,
          source.bs.qw
        );

        /** euler object representing starting orientation */
        const oEuler = new Euler().setFromQuaternion(oQuat);

        /** reset visibility */
        m.ball.visible = !this.state.inactive.includes(
          m.source.button_group.name
        );

        m.ball.position.x = source.traj.px;
        m.ball.position.y = source.traj.pz;
        m.ball.position.z = -source.traj.py;

        m.ball.rotation.x = oEuler.x;
        m.ball.rotation.y = oEuler.z;
        m.ball.rotation.z = -oEuler.y;

        m.time = 0;

        m.traj_local.px = source.traj.px;
        m.traj_local.py = source.traj.pz;
        m.traj_local.pz = -source.traj.py;

        /** todo: confirm we can remove the multiplied factor of 1.46667 (bias?) */
        m.traj_local.vx = source.traj.vx;
        m.traj_local.vy = source.traj.vz;
        m.traj_local.vz = source.traj.vy;

        m.traj_local.ax = source.traj.ax;
        m.traj_local.ay = source.traj.az;
        m.traj_local.az = -source.traj.ay;

        m.bs_local.wx = source.bs.wx;
        m.bs_local.wy = source.bs.wz;
        m.bs_local.wz = -source.bs.wy;

        m.arrived = false;
      });

      /** ensure that the animation timing is based on the moment before the interval first runs */
      this.lastTime = performance.now();

      clearInterval(this.animationInterval);
      this.animationInterval = setInterval(this.animate, 1_000 / FPS);
    });
  }

  private stepBall = (delta: number, model: ISceneModel) => {
    if (!this.props.disableCtrlSpinning && this.state.spinning) {
      /** ball will keep rotating regardless of whether flight is complete or not */
      const oNext = getNextOrientation({
        delta: delta,
        /** scale the speed by a larger number to make it spin faster */
        speed: (this.state.speed / SPEEDS.MAX) * 0.05,
        ball: model.ball,
        bs: model.source.bs,
      });

      if (oNext) {
        model.ball.rotation.x = oNext.x;
        model.ball.rotation.y = oNext.y;
        model.ball.rotation.z = oNext.z;
      }
    }

    /** continue moving ball forward + tracing its trajectory until 1 frame after it reaches/passes the strike zone */
    if (
      !model.arrived &&
      Math.abs(model.ball.position.z - STRIKEZONE.Z) > STRIKEZONE.PRECISION
    ) {
      let nextPos = positionAtTime(model.time + delta, model.traj_local);

      if (nextPos.pz >= STRIKEZONE.Z) {
        /** skip this next time */
        model.arrived = true;

        if (model.arrival_time === undefined) {
          const arrival = seekArrivalTime({
            pos: nextPos,
            timer: model,
            traj: model.traj_local,
            delta: delta,
          });

          /** save for future use */
          model.arrival_time = arrival.time;

          /** set for current animation */
          model.time = arrival.time;

          /** update value */
          nextPos = arrival.pos;
        } else {
          /** reuse the arrival time that we already figured out */
          nextPos = positionAtTime(model.arrival_time, model.traj_local);
        }
      } else {
        /** update traj time for next iteration */
        model.time += delta;
      }

      /** update ball */
      model.ball.position.x = nextPos.px;
      model.ball.position.y = nextPos.py;
      model.ball.position.z = nextPos.pz;

      /** draw line */
      model.points[0].push(
        new Vector3(
          model.ball.position.x,
          model.ball.position.y,
          model.ball.position.z
        )
      );
    }
  };

  handleWindowResize = () => {
    if (this.node) {
      this.camera.aspect = this.node.clientWidth / this.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.node.clientWidth, this.node.clientHeight);
    }
  };

  toggleGroup(name: string) {
    this.setState({
      inactive: this.state.inactive.includes(name)
        ? this.state.inactive.filter((m) => m !== name)
        : [...this.state.inactive, name],
    });
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.XS}>
          <Flex gap={RADIX.FLEX.GAP.XS} justify="between">
            <IconButton
              color={BTN_COLOR}
              variant={BTN_VARIANT}
              className={BTN_CLASS}
              title={t('common.replay-animation').toString()}
              onClick={() => this.restartAnimation('manual click')}
            >
              <LoopIcon />
            </IconButton>

            <Flex gap={RADIX.FLEX.GAP.XS}>
              {!this.props.disableCtrlSpinning && (
                <IconButton
                  color={BTN_COLOR}
                  variant={BTN_VARIANT}
                  className={BTN_CLASS}
                  title={t('common.toggle-ball-spin-animation').toString()}
                  onClick={() =>
                    this.setState({ spinning: !this.state.spinning })
                  }
                >
                  {this.state.spinning ? <GlobeIcon /> : <CircleIcon />}
                </IconButton>
              )}

              {!this.props.disableCtrlPOV && (
                <IconButton
                  color={BTN_COLOR}
                  variant={BTN_VARIANT}
                  className={BTN_CLASS}
                  title={t('common.change-camera-perspective').toString()}
                  onClick={() => {
                    const nextIndex =
                      (POV_OPTIONS.findIndex(
                        (p) => p === this.state.camera_pov
                      ) +
                        1) %
                      POV_OPTIONS.length;
                    this.setState(
                      {
                        camera_pov: POV_OPTIONS[nextIndex],
                      },
                      () => this.resetCamera()
                    );
                  }}
                >
                  <EyeOpenIcon />
                </IconButton>
              )}

              {!this.props.disableCtrlCamera && (
                <IconButton
                  color={BTN_COLOR}
                  variant={BTN_VARIANT}
                  className={BTN_CLASS}
                  title={t('common.reset-camera-position').toString()}
                  onClick={() => this.resetCamera()}
                >
                  <UpdateIcon />
                </IconButton>
              )}
            </Flex>
          </Flex>

          <Box>
            <div
              style={{
                height: '400px',
                borderRadius: '5px',
                overflow: 'hidden',
                backgroundColor: '#000000',
              }}
              ref={(elem) => (this.node = elem as HTMLDivElement)}
            />
          </Box>

          {!this.props.disableCtrlPlayback && (
            <SpeedControlView
              speed={this.state.speed}
              onChange={(value) => this.setState({ speed: value })}
            />
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
