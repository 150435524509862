import { ITrainingDot } from 'interfaces/i-canvas';

export const NEW_TRAINING_DOT: ITrainingDot = {
  backgroundColor: 'rgba(33, 34, 37, 255)',
  borderColor: 'rgba(92, 61, 5, 255)',
  labelColor: 'rgb(255, 202, 22)',
};

export const OLD_TRAINING_DOT: ITrainingDot = {
  backgroundColor: 'rgba(48, 32, 8, 255)',
  borderColor: 'rgba(54, 58, 63, 255)',
  labelColor: 'rgb(176, 180, 186)',
};

export const DOT_SIZE_SM = 8;
export const DOT_SIZE_MD = 12;
export const DOT_SIZE_LG = 16;

export const SHOT_OPACITY_DELTA = 0.1;

export const LABEL_FONT = '16px sans-serif';
