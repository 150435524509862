import { CopyIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Box, Flex, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { t } from 'i18next';
import { METERS_TO_FT } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict, getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IBallMovement,
  IBallState,
  IPitch,
  ITrajectory,
} from 'lib_ts/interfaces/pitches';
import { IRapsodoBreak } from 'lib_ts/interfaces/training/i-rapsodo-shot';
import React from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { StateTransformService } from 'services/state-transform.service';

const COMPONENT_NAME = 'OptimizePitchDialog';

interface IProps {
  pitch: IPitch;
  listsCx: IPitchListsContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  onClose: () => void;

  // hides the update button
  readonly: boolean;
}

interface IState {}

export class OptimizePitchDialog extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.getMedianBS = this.getMedianBS.bind(this);
    this.getMedianMovement = this.getMedianMovement.bind(this);
    this.getShots = this.getShots.bind(this);
    this.initialize = this.initialize.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount(): void {
    if (!this.init) {
      this.init = true;
      this.initialize();
    }
  }

  private async initialize() {
    if (this.getShots().length === 0) {
      await this.props.matchingCx.updatePitch(
        {
          pitch: this.props.pitch,
          includeHitterPresent: false,
          includeLowConfidence: false,
        },
        true
      );
    }
  }

  private getShots() {
    return this.props.matchingCx.safeGetShotsByPitch(this.props.pitch);
  }

  private getMedianBS(): IBallState | undefined {
    const ballStates = this.getShots()
      .filter((s) => !!s.bs)
      .map((s) => s.bs as IBallState);

    return ballStates.length > 0
      ? (MiscHelper.getMedianObject(ballStates) as IBallState)
      : undefined;
  }

  private getMedianMovement(): IBallMovement | undefined {
    const rapsodoBreaks = this.getShots()
      .filter((s) => !!s.break)
      .map((s) => s.break as IRapsodoBreak);

    if (rapsodoBreaks.length === 0) {
      return undefined;
    }

    const median_break = MiscHelper.getMedianObject(
      rapsodoBreaks
    ) as unknown as IRapsodoBreak;

    return {
      break_x_ft: -1 * median_break.PITCH_HBTrajectory * METERS_TO_FT,
      break_z_ft: median_break.PITCH_VBTrajectory * METERS_TO_FT,
    } as IBallMovement;
  }

  private getMedianTraj(): ITrajectory | undefined {
    const trajs = this.props.matchingCx
      .safeGetShotsByPitch(this.props.pitch)
      .filter((s) => !!s.traj)
      .map((s) => s.traj as ITrajectory);

    if (trajs.length === 0) {
      return undefined;
    }

    return MiscHelper.getMedianObject(trajs) as ITrajectory;
  }

  private async submit(mode: 'copy' | 'update') {
    if (mode === 'update' && this.props.readonly) {
      NotifyHelper.error({
        message_md: 'Updating is not allowed in read-only mode.',
      });
      return;
    }

    const median_bs = this.getMedianBS();
    const median_movement = this.getMedianMovement();
    const median_traj = this.getMedianTraj();

    if (this.props.pitch.priority === 'Spins' && !median_bs) {
      NotifyHelper.error({
        message_md: 'Optimize pitch with spin priority requires median BS.',
      });
      return;
    }

    if (
      this.props.pitch.priority === 'Breaks' &&
      (!median_movement || !this.props.pitch.breaks)
    ) {
      NotifyHelper.error({
        message_md:
          'Optimize pitch with break priority requires median breaks.',
      });
      return;
    }

    if (!median_traj) {
      NotifyHelper.error({
        message_md: 'There is no median trajectory.',
      });
      return;
    }

    const msResult = getMSFromMSDict(
      this.props.pitch,
      this.props.machineCx.machine
    );

    if (!msResult.ms) {
      NotifyHelper.error({ message_md: 'There is no machine state.' });
      return;
    }

    const target_mvmt = this.props.pitch.breaks
      ? ({
          break_x_ft: this.props.pitch.breaks.xInches / 12.0,
          break_z_ft: this.props.pitch.breaks.zInches / 12.0,
        } as IBallMovement)
      : undefined;

    const result = (
      await StateTransformService.getInstance().buildClosedLoop({
        machineID: this.props.machineCx.machine.machineID,
        pitches: [
          {
            mongo_id: this.props.pitch._id,
            ms: msResult.ms,
            target_bs: this.props.pitch.bs,
            // Fallback to the original pitch's bs if median_bs is not available
            // Only valid on break priority.
            actual_bs: median_bs ?? this.props.pitch.bs,
            traj: median_traj,
            priority: this.props.pitch.priority,
            target_mvmt: target_mvmt,
            actual_mvmt: median_movement,
            use_gradient: true,
          },
        ],
        stepSize: 1,
        notifyError: true,
      })
    ).find((p) => p.mongo_id === this.props.pitch._id);

    if (!result) {
      NotifyHelper.error({
        message_md: 'Failed to find adjusted pitch by ID.',
      });
      return;
    }

    const nextMSDict = getMergedMSDict(
      this.props.machineCx.machine,
      [result.ms],
      this.props.pitch.msDict
    );

    switch (mode) {
      case 'copy': {
        // clone the pitch but with a new msDict
        const payload: Partial<IPitch> = {
          ...this.props.pitch,
          bs: result.target_bs,
          msDict: nextMSDict,
          name: `${this.props.pitch.name} (OPT)`,
        };

        delete payload._id;

        await PitchListsService.getInstance()
          .postPitchesToList({
            listID: this.props.pitch._parent_id,
            data: [payload],
          })
          .then((result) => {
            if (!result.success) {
              NotifyHelper.warning({
                message_md: result.error ?? 'Pitch could not be created.',
              });
              return;
            }

            /** update the active pitches to show newly added pitch */
            if (
              this.props.pitch._parent_id === this.props.listsCx.active?._id
            ) {
              this.props.listsCx.refreshActive();
            }
          });
        break;
      }

      case 'update': {
        // update the pitch's msDict
        const payload: Partial<IPitch> = {
          _id: this.props.pitch._id,
          msDict: nextMSDict,
        };
        await this.props.listsCx.updatePitches({ payloads: [payload] });
        break;
      }

      default: {
        break;
      }
    }

    this.props.onClose();
  }

  render() {
    const canSave =
      (this.props.pitch.priority == 'Spins' && this.getMedianBS()) ||
      (this.props.pitch.priority == 'Breaks' &&
        this.getMedianMovement() &&
        this.props.pitch.breaks &&
        this.getMedianTraj());

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={COMPONENT_NAME}
          width={RADIX.DIALOG.WIDTH.MD}
          title={`${t('pl.optimize-pitch')} (BETA)`}
          content={
            <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
              <Box>
                <Text>
                  If a trained pitch has a different speed or spin rate than the
                  uploaded target, this is the best method to approach the
                  target.
                </Text>
              </Box>

              <CommonCallout text="Updating pitch characteristics will require retraining the pitch before it can be used." />
            </Flex>
          }
          buttons={[
            {
              icon: <CopyIcon />,
              label: 'common.copy',
              color: RADIX.COLOR.SUCCESS,
              disabled: !canSave,
              onClick: () => this.submit('copy'),
            },
            {
              invisible: this.props.readonly,
              icon: <UpdateIcon />,
              label: 'common.update',
              color: RADIX.COLOR.WARNING,
              disabled: !canSave,
              onClick: () => this.submit('update'),
            },
          ]}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
