import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Box, Button, DataList, Flex, Grid } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import {
  DOT_RGB_ACTUAL,
  DOT_RGB_ROTATED,
  DOT_RGB_TEST,
  MAX_SHOTS_USED,
  PlateCanvas,
} from 'classes/plate-canvas';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { isAfter, parseISO } from 'date-fns';
import { DOT_SIZE_LG, DOT_SIZE_MD, SHOT_OPACITY_DELTA } from 'enums/canvas';
import { CookieKey } from 'enums/cookies.enums';
import { TrainStep } from 'enums/training.enums';
import { MIN_SHOTS_TO_ROTATE } from 'lib_ts/classes/ball.helper';
import { EllipseHelper } from 'lib_ts/classes/ellipse.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch, IPlateLoc, IPlateLocExt } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React from 'react';
import Slider from 'react-input-slider';
import { ShotsService } from 'services/shots.service';

const COMPONENT_NAME = 'TrainingPlateView';

/** digits after decimal to keep, like 2 */
const DEBUG_PRECISION = 2;

/** like 100 */
const DEBUG_FACTOR = Math.pow(10, DEBUG_PRECISION);

/** seconds to wait before refiring without manual adjustment input */
const AUTO_RETRY_QUICK_SEC = 10;

const ENABLE_ROTATED_SHOTS = false;

const roundPrecision = (value: number): string => {
  return (Math.round(value * DEBUG_FACTOR) / DEBUG_FACTOR).toFixed(
    DEBUG_PRECISION
  );
};

const DEFAULT_SLIDER: IPlateLoc = {
  plate_x: 0,
  plate_z: 0,
};

interface IDebugDetail {
  label: string;
  value: string;
}

interface IProps extends Partial<IPlateLoc> {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;

  step: TrainStep;

  centeredPitch: IPitch;
  shots: IMachineShot[];
  newShotID?: string;

  // irrelevant for manual training
  onUpdateShot: (shot: IMachineShot | undefined) => void;
}

interface IState {
  /** ft */
  slider_x: number;
  /** ft */
  slider_y: number;

  shotIDs: string[];

  testing: boolean;
  test_x: number;
  test_y: number;

  sortedShots: IMachineShot[];
}

export const getPovTooltip = () => (
  <CommonTooltip trigger={<InfoCircledIcon />} text_md="pd.batters-pov" />
);

export class TrainingPlateView extends React.Component<IProps, IState> {
  private mountDate = new Date();
  private autoRetryInterval?: any;
  private autoRetrySecRemaining = 0;

  private plate_canvas = PlateCanvas.makeSimple();
  private mainCanvasNode?: HTMLCanvasElement;
  private shotsCanvasNode?: HTMLCanvasElement;

  constructor(props: IProps) {
    super(props);

    this.state = {
      slider_x: 0,
      slider_y: 0,

      testing: false,
      test_x: 0,
      test_y: 0,

      shotIDs: props.shots.map((s) => s._id).sort(),

      sortedShots: [],
    };

    this.drawMain = this.drawMain.bind(this);
    this.drawRawShots = this.drawRawShots.bind(this);
    this.drawRotatedShots = this.drawRotatedShots.bind(this);
    this.drawShots = this.drawShots.bind(this);
    this.getPlateLoc = this.getPlateLoc.bind(this);
    this.getSortedShots = this.getSortedShots.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateShot = this.updateShot.bind(this);

    this.renderDebugInfo = this.renderDebugInfo.bind(this);
    this.renderToggleShots = this.renderToggleShots.bind(this);
  }

  componentDidMount(): void {
    this.drawMain('componentDidMount');
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const nextState: Partial<IState> = {};

    const allowAdjust =
      (prevProps.step !== TrainStep.ManualAdjust &&
        this.props.step === TrainStep.ManualAdjust) ||
      (prevProps.step !== TrainStep.ManualInput &&
        this.props.step === TrainStep.ManualInput);

    if (allowAdjust) {
      nextState.slider_x = DEFAULT_SLIDER.plate_x;
      nextState.slider_y = DEFAULT_SLIDER.plate_z;

      if (this.props.authCx.current.training_mode !== TrainingMode.Manual) {
        this.autoRetrySecRemaining = AUTO_RETRY_QUICK_SEC;

        clearInterval(this.autoRetryInterval);
        this.autoRetryInterval = setInterval(() => {
          if (this.autoRetrySecRemaining > 0) {
            this.autoRetrySecRemaining--;
            return;
          }

          clearInterval(this.autoRetryInterval);
          this.props.onUpdateShot(undefined);
        }, 1_000);
      }
    }

    if (
      prevProps.step !== TrainStep.Complete &&
      this.props.step === TrainStep.Complete
    ) {
      nextState.slider_x = this.plate_canvas.PLATE_CONFIG.x.default_ft;
      nextState.slider_y = this.plate_canvas.PLATE_CONFIG.y.default_ft;
    }

    const currentShots = this.props.shots;
    const currentShotIDs = currentShots.map((s) => s._id).sort();

    if (
      this.props.centeredPitch._id !== prevProps.centeredPitch?._id ||
      MiscHelper.hashify(this.state.shotIDs) !==
        MiscHelper.hashify(currentShotIDs)
    ) {
      nextState.shotIDs = currentShotIDs;
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState as any);
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.autoRetryInterval);
  }

  getPlateLoc(): IPlateLoc {
    const output: IPlateLoc = {
      plate_x: this.state.slider_x,
      plate_z: this.state.slider_y,
    };

    return output;
  }

  async updateShot(newShot: IMachineShot) {
    if (this.props.step !== TrainStep.ManualAdjust) {
      NotifyHelper.warning({
        message_md: 'Cannot update shot when not in manual adjustment step.',
      });
      return;
    }

    /** user has input where the shot landed, according to them */
    if (!this.props.newShotID) {
      NotifyHelper.warning({
        message_md: 'Cannot update shot when no new shot has been detected.',
      });
      return;
    }

    const ms = getMSFromMSDict(
      this.props.centeredPitch,
      this.props.machineCx.machine
    ).ms;

    if (!ms) {
      NotifyHelper.warning({
        message_md: `${COMPONENT_NAME} Failed to find ms from ms dictionary`,
      });
      return;
    }

    const selectedPlate = this.getPlateLoc();

    const rotation = TrajHelper.getAltAzForTranslation(
      newShot.traj,
      {
        px: newShot.traj.px,
        pz: newShot.traj.pz,
      },
      selectedPlate
    );

    const selectedTraj = TrajHelper.getRotatedTrajectory(
      newShot.traj,
      rotation
    );

    const uShot = await ShotsService.getInstance().updateShot(
      this.props.newShotID,
      { user_traj: selectedTraj }
    );

    if (uShot) {
      this.props.onUpdateShot(uShot);
    }
  }

  onChange(pos: { x: number; y: number }) {
    if (this.state.testing) {
      this.setState(
        {
          test_x: pos.x,
          test_y: pos.y,
        },
        () => this.drawShots('onChange > test')
      );
      return;
    }

    if (
      ![TrainStep.ManualAdjust, TrainStep.ManualInput].includes(this.props.step)
    ) {
      return;
    }

    clearInterval(this.autoRetryInterval);

    this.setState({
      slider_x: pos.x,
      slider_y: pos.y,
    });
  }

  private drawMain(source: string) {
    const canvas = this.mainCanvasNode;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        this.plate_canvas.drawStrikeZoneAutoAdjustArea(ctx);
        this.plate_canvas.drawStrikeZone(ctx);
        this.plate_canvas.drawGround(ctx);
      }
    }
  }

  drawShots(source: string) {
    this.setState(
      {
        ...this.state,
        sortedShots: this.getSortedShots(),
      },
      () => {
        const canvas = this.shotsCanvasNode;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            if (this.state.testing) {
              this.plate_canvas.drawDot(
                ctx,
                {
                  plate_x: this.state.test_x,
                  plate_z: this.state.test_y,
                },
                {
                  color: `rgb(${DOT_RGB_TEST})`,
                  size: DOT_SIZE_LG,
                  label: 'Test',
                }
              );
            }

            if (this.props.step === TrainStep.Complete) {
              return;
            }

            this.drawRawShots(this.state.sortedShots, ctx);
            this.drawRotatedShots(this.state.sortedShots, ctx);
          }
        }
      }
    );
  }

  /** sort newest first */
  private getSortedShots(limit = MAX_SHOTS_USED) {
    return [...this.props.shots]
      .filter((m) => isAfter(parseISO(m._created), this.mountDate))
      .sort((a, b) => -a._created.localeCompare(b._created))
      .filter((_, i) => i < limit);
  }

  // older shots will render with decreased opacity
  private drawRawShots(
    sortedShots: IMachineShot[],
    ctx: CanvasRenderingContext2D
  ) {
    sortedShots
      .map((shot) => {
        const o: IPlateLocExt = {
          ...TrajHelper.getPlateLoc(shot.traj),
          _created: shot._created,
          _id: shot._id,
        };

        return o;
      })
      .forEach((loc, i) => {
        const emphasis = this.props.newShotID === loc._id;
        const opacity = 1 - i * SHOT_OPACITY_DELTA;

        this.plate_canvas.drawDot(ctx, loc, {
          color: `rgba(${DOT_RGB_ACTUAL}, ${opacity})`,
          size: emphasis ? DOT_SIZE_LG : DOT_SIZE_MD,
          label: emphasis
            ? `${loc.plate_x.toFixed(1)}', ${loc.plate_z.toFixed(1)}'`
            : undefined,
        });
      });
  }

  // older shots will render with decreased opacity
  private drawRotatedShots(
    sortedShots: IMachineShot[],
    ctx: CanvasRenderingContext2D
  ) {
    if (!ENABLE_ROTATED_SHOTS) {
      return;
    }

    if (!this.props.cookiesCx.app.plate_show_rotated) {
      return;
    }

    if (sortedShots.length < MIN_SHOTS_TO_ROTATE) {
      return;
    }

    const ms = getMSFromMSDict(
      this.props.centeredPitch,
      this.props.machineCx.machine
    ).ms;
    if (!ms) {
      return;
    }

    EllipseHelper.getRotatedLocations({
      ms: ms,
      traj: this.props.centeredPitch.traj,
      shots: sortedShots,
      plate_distance: this.props.machineCx.machine.plate_distance,
    }).forEach((loc, i) => {
      const emphasis = this.props.newShotID === loc._id;
      const opacity = 1 - i * SHOT_OPACITY_DELTA;

      this.plate_canvas.drawDot(ctx, loc, {
        color: `rgba(${DOT_RGB_ROTATED}, ${opacity})`,
        size: emphasis ? DOT_SIZE_LG : DOT_SIZE_MD,
        label: emphasis
          ? `${loc.plate_x.toFixed(1)}', ${loc.plate_z.toFixed(1)}'`
          : undefined,
      });
    });
  }

  private renderDebugInfo() {
    const data: IDebugDetail[] = [
      {
        label: 'Last MS Hash',
        value: this.props.machineCx.lastMSHash ?? '(none)',
      },
      {
        label: 'Current Step',
        value: this.props.step,
      },
    ];

    if (this.state.testing) {
      data.push({
        label: 'Test X,Y (ft)',
        value: `${roundPrecision(this.state.test_x)}, ${roundPrecision(
          this.state.test_y
        )}`,
      });
    }

    if (
      this.state.slider_x !== undefined &&
      this.state.slider_y !== undefined
    ) {
      data.push({
        label: 'Current X,Y (ft)',
        value: `${roundPrecision(this.state.slider_x)}, ${roundPrecision(
          this.state.slider_y
        )}`,
      });
    }

    const ms = getMSFromMSDict(
      this.props.centeredPitch,
      this.props.machineCx.machine
    ).ms;
    if (ms) {
      data.push({
        label: 'Tilt, Yaw',
        value: `${ms.tilt.toFixed(2)}, ${ms.yaw.toFixed(2)}`,
      });
    }

    /** newest first */
    const shots = this.props.shots.sort((a, b) =>
      b._created.localeCompare(a._created)
    );

    if (shots.length > 0) {
      /** list out first 5 shots' actual location */
      data.push(
        ...shots
          .filter((_, i) => i < MAX_SHOTS_USED)
          .map((s, i) => {
            const plate = TrajHelper.getPlateLoc(s.traj);

            return {
              label: `Recent #${i + 1}`,
              value: `${plate.plate_x.toFixed(2)}, ${plate.plate_z.toFixed(2)}`,
            };
          })
      );
    }

    const newShot = this.props.shots.find(
      (s) => s._id === this.props.newShotID
    );
    if (newShot) {
      data.push(
        { label: 'New Shot ID', value: newShot._id },
        {
          label: 'Tilt, Yaw',
          value: `${newShot.target_ms.tilt.toFixed(
            2
          )}, ${newShot.target_ms.yaw.toFixed(2)}`,
        }
      );
    }

    return (
      <CommonDetails summary="Debug">
        <DataList.Root mt="2">
          {data.map((detail, i) => (
            <DataList.Item key={`debug-detail-${i}`}>
              <DataList.Label>{detail.label}</DataList.Label>
              <DataList.Value>{detail.value}</DataList.Value>
            </DataList.Item>
          ))}
        </DataList.Root>
      </CommonDetails>
    );
  }

  render() {
    /** width and height on the canvas element are not the same as its style width and height
     * affects zoom/scaling of the drawings when there are differences
     */
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Box>
            <div className="slider">
              <div
                className="sizer"
                style={{
                  aspectRatio: '1.6',
                  maxHeight: '400px',
                  minHeight: '100px',
                }}
              >
                <canvas
                  ref={(node) =>
                    (this.mainCanvasNode = node as HTMLCanvasElement)
                  }
                  width={this.plate_canvas.PLATE_CONFIG.canvas.width_px}
                  height={this.plate_canvas.PLATE_CONFIG.canvas.height_px}
                />
                <canvas
                  ref={(node) =>
                    (this.shotsCanvasNode = node as HTMLCanvasElement)
                  }
                  width={this.plate_canvas.PLATE_CONFIG.canvas.width_px}
                  height={this.plate_canvas.PLATE_CONFIG.canvas.height_px}
                />
                <div className="slider-wrapper animate-fade">
                  <Slider
                    data-testid="QuickTrainPlateLocation"
                    data-shots={JSON.stringify(
                      this.state.sortedShots.map((shot) => {
                        const plateLoc = TrajHelper.getPlateLoc(shot.traj);
                        return { x: plateLoc.plate_x, z: plateLoc.plate_z };
                      })
                    )}
                    axis="xy"
                    xstep={this.plate_canvas.PLATE_CONFIG.x.step}
                    xmin={this.plate_canvas.PLATE_CONFIG.x.min_ft}
                    xmax={this.plate_canvas.PLATE_CONFIG.x.max_ft}
                    x={this.state.slider_x}
                    ystep={this.plate_canvas.PLATE_CONFIG.y.step}
                    ymin={this.plate_canvas.PLATE_CONFIG.y.min_ft}
                    ymax={this.plate_canvas.PLATE_CONFIG.y.max_ft}
                    y={this.state.slider_y}
                    onChange={this.onChange}
                    onDragEnd={() => {
                      setTimeout(() => {
                        this.drawShots('onDragEnd');
                      }, 100);
                    }}
                    styles={{
                      track: {
                        backgroundColor: 'rgba(0, 0, 255, 0)',
                        width: '100%',
                        height: '100%',
                      },
                      thumb: {
                        backgroundColor: 'rgba(0, 255, 0, 0)',
                        backgroundImage: 'url(/img/baseball.png)',
                        backgroundSize: 'cover',
                        boxShadow: 'none',
                        height: 32,
                        width: 32,
                        margin: '-16px',
                        opacity: [
                          TrainStep.ManualAdjust,
                          TrainStep.ManualInput,
                        ].includes(this.props.step)
                          ? 1
                          : 0,
                      },
                    }}
                    yreverse
                  />
                </div>
                <div
                  className="image-wrapper"
                  hidden={this.props.step !== TrainStep.Complete}
                  style={{
                    backgroundImage: "url('/img/check-circle.png')",
                  }}
                ></div>
              </div>
            </div>
          </Box>

          {env.enable.toggle_plate_shots &&
            this.props.cookiesCx &&
            this.props.centeredPitch &&
            this.props.shots &&
            this.renderToggleShots()}

          {env.enable.toggle_plate_debug &&
            this.props.cookiesCx.app.plate_show_debug &&
            this.renderDebugInfo()}
        </Flex>
      </ErrorBoundary>
    );
  }

  private renderToggleShots(): React.ReactNode {
    return (
      <Grid columns="2" gap={RADIX.FLEX.GAP.MD}>
        {ENABLE_ROTATED_SHOTS && (
          <Box>
            <Button
              className="btn-block"
              color={RADIX.COLOR.SUCCESS}
              variant={
                this.props.cookiesCx.app.plate_show_rotated
                  ? RADIX.BUTTON.VARIANT.SELECTED
                  : RADIX.BUTTON.VARIANT.NOT_SELECTED
              }
              onClick={() =>
                this.props.cookiesCx
                  .setCookie(CookieKey.app, {
                    plate_show_rotated:
                      !this.props.cookiesCx.app.plate_show_rotated,
                  })
                  .then(() => this.drawShots('toggle rotated'))
              }
            >
              Rotated:{' '}
              {this.props.cookiesCx.app.plate_show_rotated ? 'ON' : 'OFF'}
            </Button>
          </Box>
        )}

        <Box>
          <Button
            className="btn-block"
            color={RADIX.COLOR.INFO}
            variant={
              this.state.testing
                ? RADIX.BUTTON.VARIANT.SELECTED
                : RADIX.BUTTON.VARIANT.NOT_SELECTED
            }
            onClick={() =>
              this.setState({ testing: !this.state.testing }, () =>
                this.drawShots('toggle testing')
              )
            }
          >
            Testing: {this.state.testing ? 'ON' : 'OFF'}
          </Button>
        </Box>
      </Grid>
    );
  }
}
