import { IOption } from 'lib_ts/interfaces/common/i-option';

export enum ProgressDirection {
  Next = 1,
  Prev = -1,
}

export enum TrainStep {
  // separate step from firing to prevent firing while sending
  Sending = 'Sending',
  Firing = 'Firing',
  // waiting for training responses to aggregate
  Waiting = 'Waiting',
  // adjusting a shot already created (e.g. with rapsodo)
  ManualAdjust = 'ManualAdjust',
  // inputting plate location to create a shot (e.g. with manual training)
  ManualInput = 'ManualInput',
  // after manual input, if still waiting on other detections (e.g. MSBS is busted)
  Complete = 'Complete',
}

export enum PTStep {
  Sending = 'Sending',
  Firing = 'Firing',
  Waiting = 'Waiting',
  Complete = 'Complete',

  // i.e. closed loop until the first shot of an ms falls within spec
  SeekBreaks = 'SeekBreaks',
  // i.e. closed loop until the first shot of an ms falls within spec
  SeekSpeed = 'SeekSpeed',
  // i.e. closed loop until the first shot of an ms falls within spec
  SeekSpins = 'SeekSpins',
  // differentiate from sending to tell controls to save the most recent results
  SeekSuccess = 'SeekSuccess',
  // i.e. reached max attempts without completing
  SeekFailure = 'SeekFailure',
}

// minimum will be the machine's training threshold, to prevent selection of anything less
export const getSampleSizes = (minimum: number) => {
  const output: IOption[] = [];

  for (let i = minimum; i <= 25; i++) {
    // i.e. min to 10, 15, 20, 25
    if (i <= 10 || i % 5 === 0) {
      output.push({
        label: `${i} ${i === 1 ? 'shot' : 'shots'}`,
        value: i.toString(),
      });
    }
  }

  return output;
};

export const getAdjustmentsOptions = () => {
  const output: IOption[] = [];

  for (let i = 1; i <= 10; i++) {
    output.push({
      label: `${i} ${i === 1 ? 'adjustment' : 'adjustments'}`,
      value: i.toString(),
    });
  }

  return output;
};
