import { LABEL_FONT, NEW_TRAINING_DOT, OLD_TRAINING_DOT } from 'enums/canvas';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import {
  METERS_TO_INCHES,
  RAD_FULL_ROTATION,
} from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { IBreakConfig } from 'lib_ts/interfaces/i-break-config';
import {
  EMPTY_RAPSODO_BREAKS,
  IRapsodoBreak,
} from 'lib_ts/interfaces/training/i-rapsodo-shot';

const LINE_COLOR = '#999999';

const TICKS_FONT = '16px sans-serif';

export class BreakCanvas {
  // fallback to empty breaks
  static getMedian(breaks: IRapsodoBreak[]) {
    if (breaks.length === 0) {
      return EMPTY_RAPSODO_BREAKS;
    }

    return MiscHelper.getMedianObject(breaks) as IRapsodoBreak;
  }

  static makeDefault() {
    return new BreakCanvas({
      aspectRatio: 1,
      height_px: 400,
      min_height_in: -35,
      max_height_in: 35,
    });
  }

  readonly CONFIG: IBreakConfig;

  private readonly CONSTANTS_PX: {
    origin_x: number;
    origin_y: number;
  };

  constructor(config: {
    aspectRatio: number;
    height_px: number;
    min_height_in: number;
    max_height_in: number;
    step?: number;
  }) {
    const total_y_in = config.max_height_in - config.min_height_in;
    const abs_x_in = (total_y_in * config.aspectRatio) / 2;

    this.CONFIG = {
      canvas: {
        width_px: config.height_px * config.aspectRatio,
        height_px: config.height_px,
      },
      x: {
        min_in: -abs_x_in,
        max_in: abs_x_in,
        step: config.step ?? 0.00001,
      },
      y: {
        min_in: config.min_height_in,
        max_in: config.max_height_in,
        step: config.step ?? 0.00001,
      },
    };

    /** must be after plate is defined */
    this.CONSTANTS_PX = {
      origin_x: this.inchToPx(0),
      origin_y: this.inchToPxY(0),
    };
  }

  private inchToPxY(y_ft: number) {
    /** convert y_ft into a px value that works for the canvas by translating so that y.min_ft is the same as 0 for the canvas */
    const total_ft = Math.abs(this.CONFIG.y.max_in - this.CONFIG.y.min_in);
    const y_perc = (y_ft - this.CONFIG.y.min_in) / total_ft;

    /** canvas y is inverted relative to slider */
    return this.CONFIG.canvas.height_px * (1 - y_perc);
  }

  private inchToPx(x_ft: number) {
    /** convert x_ft into a px value that works for the canvas by translating so that x.min_ft is the same as 0 for the canvas */
    const total_ft = Math.abs(this.CONFIG.x.max_in - this.CONFIG.x.min_in);
    const x_perc = (x_ft - this.CONFIG.x.min_in) / total_ft;
    return this.CONFIG.canvas.width_px * x_perc;
  }

  drawRulers(ctx: CanvasRenderingContext2D, color = LINE_COLOR) {
    ctx.font = TICKS_FONT;

    ctx.strokeStyle = color;
    ctx.fillStyle = color;

    /** draw x ruler */
    const x_ruler_pos_y = Math.min(
      this.CONSTANTS_PX.origin_y,
      this.inchToPxY(this.CONFIG.y.min_in)
    );
    ctx.moveTo(0, x_ruler_pos_y);
    ctx.lineTo(this.CONFIG.canvas.width_px, x_ruler_pos_y);
    ctx.stroke();

    /** draw x ruler tick marks per foot */
    ArrayHelper.getIntegerOptions(
      Math.ceil(this.CONFIG.x.min_in),
      Math.floor(this.CONFIG.x.max_in)
    ).forEach((o) => {
      const intValue = parseInt(o.value);

      if (intValue % 10 !== 0) {
        return;
      }

      const x = this.inchToPx(intValue);
      ctx.moveTo(x, x_ruler_pos_y);
      ctx.lineTo(x, x_ruler_pos_y - 5);
      ctx.stroke();

      if (intValue !== 0) {
        ctx.fillText(
          `${o.value}"`,
          x - (intValue > 0 ? 10 : 15),
          x_ruler_pos_y - 10
        );
      }
    });

    /** draw y ruler down the middle */
    const y_ruler_pos_x = Math.min(
      this.CONSTANTS_PX.origin_x,
      this.inchToPxY(this.CONFIG.x.min_in)
    );

    ctx.moveTo(y_ruler_pos_x, this.inchToPxY(this.CONFIG.y.min_in));
    ctx.lineTo(y_ruler_pos_x, this.inchToPxY(this.CONFIG.y.max_in));
    ctx.stroke();

    const ticks = ArrayHelper.getIntegerOptions(
      Math.ceil(this.CONFIG.y.min_in),
      Math.floor(this.CONFIG.y.max_in)
    );
    ticks.forEach((o) => {
      const intValue = parseInt(o.value);

      if (intValue % 10 !== 0) {
        return;
      }

      const y = this.inchToPxY(intValue);
      ctx.moveTo(y_ruler_pos_x, y);
      ctx.lineTo(y_ruler_pos_x + 5, y);
      ctx.stroke();

      if (intValue !== 0) {
        ctx.fillText(`${intValue}"`, y_ruler_pos_x + 10, y + 5);
      }
    });
  }

  drawTarget(
    ctx: CanvasRenderingContext2D,
    loc: {
      xInches: number;
      zInches: number;
    }
  ) {
    const size = 32;

    const crosshairs = new Image(size, size);

    crosshairs.onload = () => {
      const x = this.inchToPx(loc.xInches);
      const y = this.inchToPxY(loc.zInches);
      const half = size / 2;

      // rescale the image to match target size
      ctx.drawImage(crosshairs, x - half, y - half, size, size);
    };

    crosshairs.src = '/img/crosshair.svg';
  }

  drawBreak(
    ctx: CanvasRenderingContext2D,
    loc: IRapsodoBreak,
    config: {
      isNew: boolean;
      size: number;
      index: number;
    }
  ) {
    const xInches = loc.PITCH_HBTrajectory * METERS_TO_INCHES;
    if (xInches < this.CONFIG.x.min_in || xInches > this.CONFIG.x.max_in) {
      return;
    }

    const zInches = loc.PITCH_VBTrajectory * METERS_TO_INCHES;
    if (zInches < this.CONFIG.y.min_in || zInches > this.CONFIG.y.max_in) {
      return;
    }

    const colors = config.isNew ? NEW_TRAINING_DOT : OLD_TRAINING_DOT;

    ctx.fillStyle = colors.backgroundColor;
    ctx.strokeStyle = colors.borderColor;

    ctx.beginPath();
    ctx.ellipse(
      this.inchToPx(xInches),
      this.inchToPxY(zInches),
      config.size,
      config.size,
      0,
      0,
      RAD_FULL_ROTATION
    );

    ctx.fill();
    ctx.stroke();

    ctx.font = LABEL_FONT;
    ctx.fillStyle = colors.labelColor;

    // inside the circle
    ctx.fillText(
      config.index.toString(),
      this.inchToPx(xInches) - config.size / 2 + 1,
      this.inchToPxY(zInches) + config.size / 2
    );
  }
}
